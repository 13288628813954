<template>
  <v-dialog :value="showTasksFilterDialog" max-width="550px" persistent>
    <v-card>
      <v-toolbar dark color="#3F51B5" class="elevation-0">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="$emit(`tasks-filter-dialog-close`)">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-3">
        <div>Filter</div>
        <v-card class="mb-5 px-0" elevation="0">
          <v-card-text class="px-0 mx-0">
            <div class="d-flex flex-wrap gap">
              <v-chip
                v-for="(f, i) of filterChoices"
                :key="f.selectedField"
                close
                @click:close="removeFilter(i)"
              >
                <div class="chip my-0 py-0">
                  {{ f.selectedField | taskFields }}
                  <MatchIcon :value="f.selectedMatchChoice" class="mx-2" />
                  {{
                    getDisplayMatchValue(f.selectedField, f.selectedMatchValue)
                  }}
                </div>
              </v-chip>
            </div>
          </v-card-text>
        </v-card>

        <div>Add to Filter</div>
        <v-card>
          <v-card-text
            class="align-center justify-space-between gap"
            :class="{ 'd-flex': $vuetify.breakpoint.smAndUp }"
          >
            <v-select
              :items="fieldChoices"
              v-model="selectedField"
              item-text="label"
              item-value="value"
              label="Field"
              :style="{
                width: $vuetify.breakpoint.smAndUp ? '100%' : undefined,
              }"
              hide-details
            >
            </v-select>

            <v-select
              :items="filteredMatchChoices"
              v-model="selectedMatchChoice"
              item-text="label"
              item-value="value"
              flat
              hide-details
              :class="{ 'field-select': $vuetify.breakpoint.smAndUp }"
              style="width: 100px"
            >
              <template #item="{ item: { label, value } }">
                <div>
                  <MatchIcon :value="value" />
                  {{ label }}
                </div>
              </template>

              <template #selection="{ item: { label, value } }">
                <div>
                  <MatchIcon :value="value" />
                  {{ $vuetify.breakpoint.smAndUp ? undefined : label }}
                </div>
              </template>
            </v-select>

            <div
              :style="{
                width: $vuetify.breakpoint.smAndUp ? '100%' : undefined,
              }"
            >
              <v-menu
                v-if="fieldType === FIELD_TYPES.DATE"
                v-model="openDatePicker"
                :close-on-click="false"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <validation-provider
                    v-slot="{ errors, valid }"
                    name="Value"
                    rules="required"
                  >
                    <v-text-field
                      v-on="on"
                      v-model="selectedMatchValue"
                      hide-details
                      :error-messages="errors"
                      :success="valid"
                    >
                      <template #append>
                        <v-btn
                          small
                          icon
                          @click="
                            on.click($event);
                            openDatePicker = true;
                          "
                        >
                          <v-icon color="#3F51B5">
                            {{ mdiCalendar }}
                          </v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </validation-provider>
                </template>
                <v-date-picker
                  v-if="openDatePicker"
                  v-model="selectedMatchValue"
                  :range="selectedMatchChoice === MATCH_CHOICE_VALUES.RANGE"
                  v-click-outside="onDatePickerClickOutside"
                  @click:date="onDateClick()"
                >
                </v-date-picker>
              </v-menu>
              <template v-else>
                <v-autocomplete
                  v-if="
                    [
                      MATCH_CHOICE_VALUES.EQUALS,
                      MATCH_CHOICE_VALUES.DOES_NOT_EQUAL,
                    ].includes(selectedMatchChoice)
                  "
                  v-model="selectedMatchValue"
                  :items="valueChoices"
                  item-text="label"
                  item-value="value"
                  hide-details
                >
                  <template v-slot:item="{ item }">
                    <section>
                      <div
                        v-if="selectedField === 'formDefinitionId'"
                        class="caption pt-2"
                      >
                        {{ item.layer }}
                      </div>
                      <div
                        :class="
                          selectedField === 'formDefinitionId' ? 'pb-2' : ''
                        "
                      >
                        {{ item.label }}
                      </div>
                    </section>
                  </template>
                </v-autocomplete>
                <v-text-field v-else v-model="selectedMatchValue" hide-details>
                </v-text-field>
              </template>
            </div>

            <v-btn icon color="#3F51B5" @click="addToFilter">
              <v-icon>
                {{ mdiFilterPlus }}
              </v-icon>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-card-text>

      <v-card-actions class="d-flex justify-end px-5 py-3">
        <v-btn
          elevation="2"
          color="#3F51B5"
          @click="updateFilter"
          :disabled="!filterChanged"
          :dark="filterChanged"
        >
          <span color="white">Update Filter</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mdiClose,
  mdiFilterPlus,
  mdiCalendar,
  mdiCalendarArrowLeft,
  mdiCalendarArrowRight,
} from "@mdi/js";
import MATCH_CHOICE_VALUES from "@/constants/matchChoiceValues";
import FIELD_TYPES from "@/constants/fieldTypes";
import { cloneDeep } from "lodash";
import MatchIcon from "@/components/list/gis-data-point-filter-dialog/MatchIcon";
import moment from "moment";
import networkStatusMixin from "@/mixins/networkStatusMixin";

const fieldChoices = [
  { label: "Due", value: "due", fieldType: FIELD_TYPES.DATE },
  { label: "Assigned To", value: "assignedTo", fieldType: FIELD_TYPES.STRING },
  { label: "Form", value: "formDefinitionId", fieldType: FIELD_TYPES.STRING },
  { label: "Description", value: "description", fieldType: FIELD_TYPES.STRING },
  { label: "Site", value: "featureId", fieldType: FIELD_TYPES.STRING },
  { label: "Status", value: "status", fieldType: FIELD_TYPES.STRING },
  { label: "Assigned By", value: "assignedBy", fieldType: FIELD_TYPES.STRING },
  { label: "Assigned On", value: "assignedOn", fieldType: FIELD_TYPES.DATE },
  { label: "Task Type", value: "taskTypeId", fieldType: FIELD_TYPES.STRING },
];

const onlineStatusChoices = [
  { label: "Open", value: "Open" },
  { label: "Closed", value: "Closed" },
  { label: "Cancelled", value: "Cancelled" },
];

const offlineStatusChoices = [{ label: "Open", value: "Open" }];

export default {
  name: "TasksFilterDialog",
  props: {
    showTasksFilterDialog: Boolean,
    savedFilterChoices: Array,
    tasks: Array,
    users: Array,
    formDefinitions: Array,
    taskTypes: Array,
    siteInfos: Array,
    mapServices: Array,
  },
  mixins: [networkStatusMixin],
  components: {
    MatchIcon,
  },
  data() {
    return {
      fieldChoices,
      mdiClose,
      mdiFilterPlus,
      mdiCalendar,
      mdiCalendarArrowLeft,
      mdiCalendarArrowRight,
      MATCH_CHOICE_VALUES,
      selectedField: undefined,
      selectedMatchChoice: MATCH_CHOICE_VALUES.EQUALS,
      selectedMatchValue: undefined,
      filterChoices: [
        {
          selectedField: "status",
          selectedMatchChoice: MATCH_CHOICE_VALUES.EQUALS,
          selectedMatchValue: "Open",
        },
      ],
      filterChanged: false,
      FIELD_TYPES,
      openDatePicker: false,
    };
  },
  computed: {
    fieldType() {
      return this.fieldChoices.find((f) => f.value === this.selectedField)
        ?.fieldType;
    },
    filteredMatchChoices() {
      const { fieldType, selectedField } = this;
      if (fieldType === FIELD_TYPES.STRING) {
        if (selectedField === "description") {
          return [
            { label: "Contains", value: MATCH_CHOICE_VALUES.CONTAINS },
            {
              label: "Does not Contain",
              value: MATCH_CHOICE_VALUES.DOES_NOT_CONTAIN,
            },
          ];
        } else if (
          [
            "taskTypeId",
            "assignedBy",
            "status",
            "featureId",
            "assignedTo",
            "formDefinitionId",
          ].includes(selectedField)
        ) {
          return [
            { label: "Equals", value: MATCH_CHOICE_VALUES.EQUALS },
            {
              label: "Does not Equal",
              value: MATCH_CHOICE_VALUES.DOES_NOT_EQUAL,
            },
          ];
        } else {
          return [{ label: "Equals", value: MATCH_CHOICE_VALUES.EQUALS }];
        }
      } else if (fieldType === FIELD_TYPES.NUMBER) {
        return [
          { label: "Equals", value: MATCH_CHOICE_VALUES.EQUALS },
          { label: "Greater Than", value: MATCH_CHOICE_VALUES.GREATER_THAN },
          {
            label: "Greater Than or Equal",
            value: MATCH_CHOICE_VALUES.GREATER_THAN_OR_EQUAL,
          },
          { label: "Less Than", value: MATCH_CHOICE_VALUES.LESS_THAN },
          {
            label: "Less Than or Equal",
            value: MATCH_CHOICE_VALUES.LESS_THAN_OR_EQUAL,
          },
          {
            label: "Does not Equal",
            value: MATCH_CHOICE_VALUES.DOES_NOT_EQUAL,
          },
        ];
      } else if (fieldType === FIELD_TYPES.DATE) {
        if (["assignedOn", "due"].includes(selectedField)) {
          return [
            {
              label: "On or After",
              value: MATCH_CHOICE_VALUES.ON_OR_AFTER,
            },
            {
              label: "On or Before",
              value: MATCH_CHOICE_VALUES.ON_OR_BEFORE,
            },
            {
              label: "Range",
              value: MATCH_CHOICE_VALUES.RANGE,
            },
          ];
        } else {
          return [
            {
              label: "On or After",
              value: MATCH_CHOICE_VALUES.ON_OR_AFTER,
            },
            {
              label: "On or Before",
              value: MATCH_CHOICE_VALUES.ON_OR_BEFORE,
            },
          ];
        }
      }
      return [];
    },
    userChoices() {
      if (!Array.isArray(this.users)) {
        return [];
      }

      const activeUsers = this.users
        .filter((u) => u.is_active)
        .map((u) => {
          const { f_name: fName, l_name: lName, user_id: value } = u;
          return {
            label: `${fName} ${lName}`,
            value,
            group: "Active Users",
          };
        });
      const inactiveUsers = this.users
        .filter((u) => !u.is_active)
        .map((u) => {
          const { f_name: fName, l_name: lName, user_id: value } = u;
          return {
            label: `${fName} ${lName}`,
            value,
            group: "Inactive Users",
          };
        });
      return [
        { header: "Active Users" },
        ...activeUsers,
        { header: "Inactive Users" },
        ...inactiveUsers,
      ];
    },
    taskTypesChoices() {
      return (
        this.taskTypes?.map((tt) => {
          const { name, task_type_id: taskTypeId } = tt;
          return {
            label: name,
            value: taskTypeId,
          };
        }) ?? []
      );
    },
    siteChoices() {
      return (
        this.siteInfos
          ?.map((s) => {
            return {
              label: s?.siteName,
              value: s?.siteName,
            };
          })
          ?.filter((s) => s.label && s.value) ?? []
      );
    },
    formDefinitionChoices() {
      return (
        this.formDefinitions
          .map((f) => {
            return {
              label: f?.form?.formDescription?.title,
              value: f?.form_definition_id,
              layer: this.mapServices.find((s) => {
                return s?.map_service_id === f.map_service_id;
              }).service_name,
            };
          })
          .sort((a, b) => a.label.localeCompare(b.label)) ?? []
      );
    },
    valueChoices() {
      if (
        this.selectedField === "assignedTo" ||
        this.selectedField === "assignedBy"
      ) {
        return this.userChoices;
      } else if (this.selectedField === "taskTypeId") {
        return this.taskTypesChoices;
      } else if (this.selectedField === "featureId") {
        return this.siteChoices;
      } else if (this.selectedField === "status") {
        if (this.isOnline) {
          return onlineStatusChoices;
        } else {
          return offlineStatusChoices;
        }
      } else if (this.selectedField === "formDefinitionId") {
        return this.formDefinitionChoices;
      }
      return [];
    },
  },
  methods: {
    onDateClick() {
      if (this.selectedMatchChoice === MATCH_CHOICE_VALUES.RANGE) {
        this.openDatePicker = (this.selectedMatchValue?.length ?? 0) < 2;
      } else {
        this.openDatePicker = false;
      }
    },
    onDatePickerClickOutside() {
      this.openDatePicker = false;
    },
    addToFilter() {
      const { selectedField, selectedMatchChoice, selectedMatchValue } = this;
      const filter = { selectedField, selectedMatchChoice, selectedMatchValue };
      const index = this.filterChoices.findIndex((fc) => {
        return fc.selectedField === selectedField;
      });
      if (index >= 0) {
        this.filterChoices.splice(index, 1);
      }

      let newFilter;
      if (Array.isArray(selectedMatchValue)) {
        newFilter = {
          ...filter,
          selectedMatchValue: selectedMatchValue.sort(
            (a, b) => +moment(a).toDate() - +moment(b).toDate()
          ),
        };
      } else {
        newFilter = {
          ...filter,
          selectedMatchValue,
        };
      }
      this.filterChoices.push(newFilter);
      this.selectedField = undefined;
      this.selectedMatchChoice = MATCH_CHOICE_VALUES.EQUALS;
      this.selectedMatchValue = undefined;
      this.filterChanged = true;
    },
    updateFilter() {
      this.$emit("update-filter", this.filterChoices);
    },
    removeFilter(index) {
      this.filterChoices.splice(index, 1);
      this.filterChanged = true;
    },
    getDisplayMatchValue(selectedField, selectedMatchValue) {
      if (selectedField === "assignedTo" || selectedField === "assignedBy") {
        const user = this.users?.find((u) => u.user_id === selectedMatchValue);
        const { f_name: fName, l_name: lName } = user ?? {};
        return `${fName} ${lName}`;
      } else if (selectedField === "formDefinitionId") {
        return this.formDefinitions?.find((f) => {
          return selectedMatchValue === f?.form_definition_id;
        })?.form?.formDescription?.title;
      } else if (selectedField === "taskTypeId") {
        return this.taskTypes.find(
          (tt) => tt.task_type_id === selectedMatchValue
        )?.name;
      }
      return selectedMatchValue;
    },
  },
  watch: {
    filteredMatchChoices: {
      deep: true,
      handler(val) {
        const [firstChoice] = val;
        this.selectedMatchChoice = firstChoice?.value;
      },
    },
    selectedField() {
      this.selectedMatchValue = undefined;
    },
    selectedMatchChoice() {
      this.selectedMatchValue = undefined;
    },
  },
  beforeMount() {
    this.filterChoices = cloneDeep(this.savedFilterChoices);
  },
};
</script>

<style scoped>
.field-select >>> .v-input__slot::before {
  border-style: none;
}
</style>
