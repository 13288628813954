var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showConfirmDeleteDialog,
        "max-width": "550px",
        persistent: ""
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-text", { staticClass: "py-3" }, [
            _c("h2", { staticClass: "font-weight-regular" }, [
              _vm._v("Delete Task")
            ]),
            _c("p", { staticClass: "py-3" }, [
              _vm._v(
                " Are you sure you want to delete this task? This action can’t be undone. "
              )
            ])
          ]),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end" },
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "#3F51B5" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("cancel-delete-task")
                    }
                  }
                },
                [
                  _c("span", { staticClass: "font-weight-regular" }, [
                    _vm._v("Cancel")
                  ])
                ]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "#3F51B5", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("delete-task")
                    }
                  }
                },
                [
                  _c("span", { staticClass: "font-weight-regular" }, [
                    _vm._v("Delete Task")
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }