<template>
  <v-dialog :value="showConfirmDeleteDialog" max-width="550px" persistent>
    <v-card>
      <v-card-text class="py-3">
        <h2 class="font-weight-regular">Delete Task</h2>
        <p class="py-3">
          Are you sure you want to delete this task? This action can’t be
          undone.
        </p>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn text color="#3F51B5" @click="$emit('cancel-delete-task')">
          <span class="font-weight-regular">Cancel</span>
        </v-btn>
        <v-btn color="#3F51B5" @click="$emit('delete-task')" dark>
          <span class="font-weight-regular">Delete Task</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmDeleteTaskDialog",
  props: {
    showConfirmDeleteDialog: Boolean,
  },
};
</script>
