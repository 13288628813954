var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showUnableToObtainGisAttributesDialog,
        "max-width": "550px"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-text", { staticClass: "py-3" }, [
            _c("h2", { staticClass: "font-weight-regular" }, [
              _vm._v("Unable to Obtain GIS Attributes")
            ]),
            _c("p", { staticClass: "py-3" }, [
              _vm._v(
                " We were unable to obtain the GIS attributes for this feature. Do you want to continue with creating the form? If you continue, there may be some fields on the form that do not pre-populate as expected. "
              )
            ])
          ]),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end px-3" },
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("cancel")
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { dark: "", color: "#3F51B5" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("create-form")
                    }
                  }
                },
                [_vm._v(" Create Form ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }