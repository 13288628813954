<template>
  <v-dialog :value="showTaskPageEditTaskDialog" max-width="500px">
    <v-card>
      <v-toolbar dark class="elevation-0" width="100%" color="#3F51B5">
        <div>Edit Scheduled Form</div>
        <v-spacer />
        <v-btn icon @click="$emit('task-page-edit-task-dialog-close')">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>

      <form @submit.prevent="saveTask">
        <validation-observer ref="form">
          <v-card-text
            style="background-color: #fafafa; max-height: 60vh"
            class="overflow-y-auto"
          >
            <p class="caption">Scheduled Form Info</p>
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <div class="half">
                      <validation-provider
                        v-slot="{ errors, valid }"
                        name="Form To Be Completed"
                        rules="required"
                      >
                        <v-select
                          v-model="task.formDefinitionId"
                          :items="formChoices"
                          hide-details="auto"
                          name="form"
                          item-value="value"
                          item-text="name"
                          :error-messages="errors"
                          :success="valid"
                          label="Form To Be Completed *"
                        />
                      </validation-provider>
                    </div>
                  </v-col>

                  <v-col cols="12">
                    <div class="half">
                      <validation-provider
                        v-slot="{ errors, valid }"
                        name="Assigned To"
                        rules="required"
                      >
                        <v-autocomplete
                          v-model="task.assignedTo"
                          :items="userChoices"
                          hide-details="auto"
                          name="assignedTo"
                          item-value="value"
                          item-text="name"
                          :error-messages="errors"
                          :success="valid"
                          label="Assigned To *"
                          class="autocomplete"
                        />
                      </validation-provider>
                    </div>
                  </v-col>

                  <v-col cols="12">
                    <div class="half">
                      <validation-provider
                        v-slot="{ errors, valid }"
                        name="Due Date"
                        rules="required"
                      >
                        <v-menu>
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-on="on"
                              v-model="task.due"
                              :error-messages="errors"
                              :success="valid"
                              color="#3F51B5"
                              label="Due Date *"
                            >
                              <template #append>
                                <v-icon>
                                  {{ mdiCalendar }}
                                </v-icon>
                              </template>
                            </v-text-field>
                          </template>
                          <v-date-picker v-model="task.due"></v-date-picker>
                        </v-menu>
                      </validation-provider>
                    </div>
                  </v-col>

                  <v-col cols="12">
                    <div class="half">
                      <validation-provider
                        v-slot="{ errors, valid }"
                        name="Status"
                        rules="required"
                      >
                        <v-select
                          v-model="task.status"
                          :items="statusChoices"
                          hide-details="auto"
                          name="status"
                          item-value="value"
                          item-text="name"
                          :error-messages="errors"
                          :success="valid"
                          label="Status *"
                        />
                      </validation-provider>
                    </div>
                  </v-col>

                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="Description"
                    >
                      <v-text-field
                        v-model="task.description"
                        :error-messages="errors"
                        :success="valid"
                        label="Description"
                        color="#3F51B5"
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12">
                    <div class="d-flex align-end">
                      <div class="half">
                        <validation-provider
                          v-slot="{ errors, valid }"
                          name="Notification Template"
                        >
                          <v-select
                            v-model="task.taskNotificationTemplateId"
                            :items="taskNotificationTemplateChoices"
                            hide-details="auto"
                            name="notificationTemplate"
                            item-value="value"
                            item-text="name"
                            :error-messages="errors"
                            :success="valid"
                            label="Notification Template"
                            clearable
                            :disabled="!isOnline"
                          />
                        </validation-provider>
                      </div>

                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>{{ mdiDotsVertical }}</v-icon>
                          </v-btn>
                        </template>

                        <v-list class="px-0 mx-0">
                          <v-list-item
                            class="
                              d-flex
                              flex-no-wrap
                              gap
                              px-3
                              mx-0
                              cursor-pointer
                            "
                          >
                            <div>
                              <v-icon>
                                {{ mdiPencil }}
                              </v-icon>
                            </div>
                            <div>Notification Templates</div>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card-text>
          <v-card-actions class="d-flex justify-end py-3 px-4">
            <v-btn type="submit" color="#3F51B5" dark>Update</v-btn>
          </v-card-actions>
        </validation-observer>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose, mdiCalendar, mdiPencil, mdiDotsVertical } from "@mdi/js";
import { axiosWithRegularAuth } from "@/plugins/axios";
import moment from "moment";
import { db } from "@/mixins/utilisync-db";
import networkStatusMixin from "@/mixins/networkStatusMixin";

const APIURL = process.env.VUE_APP_API_URL;

const statusChoices = [
  { name: "Open", value: "Open" },
  { name: "Closed", value: "Closed" },
  { name: "Cancelled", value: "Cancelled" },
];

export default {
  name: "TaskPageEditTaskDialog",
  props: {
    selectedMapServiceId: String,
    objectId: Number,
    globalId: String,
    showTaskPageEditTaskDialog: Boolean,
    selectedTask: Object,
    taskTypes: Array,
  },
  mixins: [networkStatusMixin],
  computed: {
    userChoices() {
      return this.users
        .filter((u) => u.is_active && !u.is_contact)
        .map((u) => {
          const { f_name: fName, l_name: lName, user_id: value } = u;
          return {
            name: `${fName} ${lName}`,
            value,
          };
        });
    },
    formChoices() {
      return this.forms.map((f) => {
        const { title, form_definition_id: formDefId } = f;
        return {
          name: title,
          value: formDefId,
        };
      });
    },
    taskNotificationTemplateChoices() {
      return this.taskNotificationTemplates.map((t) => {
        const {
          task_notification_template_id: tId,
          task_notification_template_name: name,
        } = t;
        return {
          name,
          value: tId,
        };
      });
    },
    taskTypeChoices() {
      return this.taskTypes.map((t) => {
        const { name, task_type_id: value } = t;
        return { name, value };
      });
    },
  },
  data() {
    return {
      mdiClose,
      mdiCalendar,
      mdiPencil,
      mdiDotsVertical,
      task: {
        formDefId: "",
        assignedTo: "",
        description: "",
        due: "",
        taskNotificationTemplateId: "",
        status: "",
      },
      users: [],
      forms: [],
      taskNotificationTemplates: [],
      statusChoices,
    };
  },
  methods: {
    async saveTask() {
      const success = await this.$refs.form.validate();
      if (!success) {
        return;
      }

      const {
        assignedTo,
        description,
        due,
        taskNotificationTemplateId,
        formDefinitionId,
        status,
        featureId,
        globalId,
        mapServiceId,
      } = this.task;
      const payload = {
        map_service_id: mapServiceId,
        feature_id: featureId,
        description,
        assigned_to: assignedTo,
        due: moment.utc(due).format("YYYY-MM-DD"),
        assignee_closable: true,
        task_notification_template_id: taskNotificationTemplateId,
        task_type_id: this.taskTypeChoices.find(
          (tt) => tt.name === "User Created Task"
        )?.value,
        form_definition_id: formDefinitionId,
        status,
        globalId: globalId,
      };
      await axiosWithRegularAuth.put(
        `${APIURL}/tasks/${this.selectedTask.taskId}`,
        payload
      );
      const [task] = await db.tasks
        .filter((t) => t.task_id === this.selectedTask.taskId)
        .toArray();
      db.tasks.put({ ...task, ...payload });
      this.$emit("edit-task-success", this.selectedTask.taskId);
    },
    async getUsers() {
      const collection = await db.users.orderBy("f_name");
      this.users = await collection.toArray();
    },
    async getForms() {
      const [mapService] = await db.mapServices
        .filter((m) => m.map_service_id === this.selectedTask.mapServiceId)
        .toArray();
      this.forms = mapService?.forms ?? [];
    },
    async getTaskNotificationTemplates() {
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/task_notification_templates`,
        {}
      );
      this.taskNotificationTemplates = results;
    },
  },
  beforeMount() {
    this.getTaskNotificationTemplates();
    this.getUsers();
    this.getForms();
    const {
      assignedTo,
      formDefinitionId,
      description,
      due,
      taskTypeId,
      status,
    } = this.selectedTask;

    this.task = {
      assignedTo,
      formDefinitionId,
      description,
      due: moment.utc(due).isValid()
        ? moment.utc(due).format("YYYY-MM-DD")
        : "",
      taskTypeId,
      status,
    };
  },
};
</script>

<style scoped>
.half {
  width: 50%;
}
</style>
