var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-wrap gap" },
    _vm._l(_vm.filterChoices, function(f, i) {
      return _c(
        "v-chip",
        {
          key: f.selectedField,
          attrs: { close: "" },
          on: {
            "click:close": function($event) {
              return _vm.$emit("remove-filter", i)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "chip my-0 py-0" },
            [
              _vm._v(" " + _vm._s(_vm._f("taskFields")(f.selectedField)) + " "),
              _c("MatchIcon", {
                staticClass: "mx-2",
                attrs: { value: f.selectedMatchChoice }
              }),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.getDisplayMatchValue(
                      f.selectedField,
                      f.selectedMatchValue
                    )
                  ) +
                  " "
              )
            ],
            1
          )
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }