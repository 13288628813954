var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pr-3" },
    [
      _c("v-select", {
        staticClass: "flex-grow-0 py-0 my-0",
        staticStyle: { "max-width": "180px" },
        attrs: {
          items: _vm.TABLE_CHOICES,
          value: _vm.currentSelectedTable,
          "item-text": "label",
          "item-value": "value",
          label: "List"
        },
        on: {
          input: function($event) {
            return _vm.tableSelected()
          }
        },
        model: {
          value: _vm.selectedTable,
          callback: function($$v) {
            _vm.selectedTable = $$v
          },
          expression: "selectedTable"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }