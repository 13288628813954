<template>
  <div class="pr-3">
    <v-select
      :items="TABLE_CHOICES"
      :value="currentSelectedTable"
      v-model="selectedTable"
      item-text="label"
      item-value="value"
      class="flex-grow-0 py-0 my-0"
      style="max-width: 180px"
      label="List"
      @input="tableSelected()"
    >
    </v-select>
  </div>
</template>

<script>
const TABLES = {
  LAYERS: "layers",
  TASKS: "tasks",
  ACTION_ITEMS: "action-items",
  FORM_SUBMISSION: "form-submission",
};

const TABLE_CHOICES = [
  { label: "Layers", value: TABLES.LAYERS },
  { label: "Scheduled Forms", value: TABLES.TASKS },
  { label: "Action items", value: TABLES.ACTION_ITEMS },
  { label: "Form Submissions", value: TABLES.FORM_SUBMISSION },
];

export default {
  data() {
    return {
      selectedTable: this.currentSelectedTable,
      TABLE_CHOICES,
    };
  },
  methods: {
    tableSelected() {
      this.$emit("selected-table", this.selectedTable);
    },
  },
  props: {
    currentSelectedTable: String,
  },
};
</script>
