var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.showTaskPageEditTaskDialog, "max-width": "500px" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            {
              staticClass: "elevation-0",
              attrs: { dark: "", width: "100%", color: "#3F51B5" }
            },
            [
              _c("div", [_vm._v("Edit Scheduled Form")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("task-page-edit-task-dialog-close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                1
              )
            ],
            1
          ),
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.saveTask.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "validation-observer",
                { ref: "form" },
                [
                  _c(
                    "v-card-text",
                    {
                      staticClass: "overflow-y-auto",
                      staticStyle: {
                        "background-color": "#fafafa",
                        "max-height": "60vh"
                      }
                    },
                    [
                      _c("p", { staticClass: "caption" }, [
                        _vm._v("Scheduled Form Info")
                      ]),
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "12" } }, [
                                    _c(
                                      "div",
                                      { staticClass: "half" },
                                      [
                                        _c("validation-provider", {
                                          attrs: {
                                            name: "Form To Be Completed",
                                            rules: "required"
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                var valid = ref.valid
                                                return [
                                                  _c("v-select", {
                                                    attrs: {
                                                      items: _vm.formChoices,
                                                      "hide-details": "auto",
                                                      name: "form",
                                                      "item-value": "value",
                                                      "item-text": "name",
                                                      "error-messages": errors,
                                                      success: valid,
                                                      label:
                                                        "Form To Be Completed *"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.task
                                                          .formDefinitionId,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.task,
                                                          "formDefinitionId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "task.formDefinitionId"
                                                    }
                                                  })
                                                ]
                                              }
                                            }
                                          ])
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("v-col", { attrs: { cols: "12" } }, [
                                    _c(
                                      "div",
                                      { staticClass: "half" },
                                      [
                                        _c("validation-provider", {
                                          attrs: {
                                            name: "Assigned To",
                                            rules: "required"
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                var valid = ref.valid
                                                return [
                                                  _c("v-autocomplete", {
                                                    staticClass: "autocomplete",
                                                    attrs: {
                                                      items: _vm.userChoices,
                                                      "hide-details": "auto",
                                                      name: "assignedTo",
                                                      "item-value": "value",
                                                      "item-text": "name",
                                                      "error-messages": errors,
                                                      success: valid,
                                                      label: "Assigned To *"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.task.assignedTo,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.task,
                                                          "assignedTo",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "task.assignedTo"
                                                    }
                                                  })
                                                ]
                                              }
                                            }
                                          ])
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("v-col", { attrs: { cols: "12" } }, [
                                    _c(
                                      "div",
                                      { staticClass: "half" },
                                      [
                                        _c("validation-provider", {
                                          attrs: {
                                            name: "Due Date",
                                            rules: "required"
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                var valid = ref.valid
                                                return [
                                                  _c(
                                                    "v-menu",
                                                    {
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  "v-text-field",
                                                                  _vm._g(
                                                                    {
                                                                      attrs: {
                                                                        "error-messages": errors,
                                                                        success: valid,
                                                                        color:
                                                                          "#3F51B5",
                                                                        label:
                                                                          "Due Date *"
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "append",
                                                                            fn: function() {
                                                                              return [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          _vm.mdiCalendar
                                                                                        ) +
                                                                                        " "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            },
                                                                            proxy: true
                                                                          }
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .task
                                                                            .due,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.task,
                                                                            "due",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "task.due"
                                                                      }
                                                                    },
                                                                    on
                                                                  )
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _c("v-date-picker", {
                                                        model: {
                                                          value: _vm.task.due,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.task,
                                                              "due",
                                                              $$v
                                                            )
                                                          },
                                                          expression: "task.due"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ])
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("v-col", { attrs: { cols: "12" } }, [
                                    _c(
                                      "div",
                                      { staticClass: "half" },
                                      [
                                        _c("validation-provider", {
                                          attrs: {
                                            name: "Status",
                                            rules: "required"
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                var valid = ref.valid
                                                return [
                                                  _c("v-select", {
                                                    attrs: {
                                                      items: _vm.statusChoices,
                                                      "hide-details": "auto",
                                                      name: "status",
                                                      "item-value": "value",
                                                      "item-text": "name",
                                                      "error-messages": errors,
                                                      success: valid,
                                                      label: "Status *"
                                                    },
                                                    model: {
                                                      value: _vm.task.status,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.task,
                                                          "status",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "task.status"
                                                    }
                                                  })
                                                ]
                                              }
                                            }
                                          ])
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("validation-provider", {
                                        attrs: { name: "Description" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              var valid = ref.valid
                                              return [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    "error-messages": errors,
                                                    success: valid,
                                                    label: "Description",
                                                    color: "#3F51B5"
                                                  },
                                                  model: {
                                                    value: _vm.task.description,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.task,
                                                        "description",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "task.description"
                                                  }
                                                })
                                              ]
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  ),
                                  _c("v-col", { attrs: { cols: "12" } }, [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex align-end" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "half" },
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                name: "Notification Template"
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    var valid = ref.valid
                                                    return [
                                                      _c("v-select", {
                                                        attrs: {
                                                          items:
                                                            _vm.taskNotificationTemplateChoices,
                                                          "hide-details":
                                                            "auto",
                                                          name:
                                                            "notificationTemplate",
                                                          "item-value": "value",
                                                          "item-text": "name",
                                                          "error-messages": errors,
                                                          success: valid,
                                                          label:
                                                            "Notification Template",
                                                          clearable: "",
                                                          disabled: !_vm.isOnline
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.task
                                                              .taskNotificationTemplateId,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.task,
                                                              "taskNotificationTemplateId",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "task.taskNotificationTemplateId"
                                                        }
                                                      })
                                                    ]
                                                  }
                                                }
                                              ])
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-menu",
                                          {
                                            attrs: { "offset-y": "" },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: { icon: "" }
                                                          },
                                                          "v-btn",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.mdiDotsVertical
                                                            )
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ])
                                          },
                                          [
                                            _c(
                                              "v-list",
                                              { staticClass: "px-0 mx-0" },
                                              [
                                                _c(
                                                  "v-list-item",
                                                  {
                                                    staticClass:
                                                      "\n                            d-flex\n                            flex-no-wrap\n                            gap\n                            px-3\n                            mx-0\n                            cursor-pointer\n                          "
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.mdiPencil
                                                              ) +
                                                              " "
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        "Notification Templates"
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    { staticClass: "d-flex justify-end py-3 px-4" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { type: "submit", color: "#3F51B5", dark: "" }
                        },
                        [_vm._v("Update")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }