var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-navigation-drawer",
        {
          style: {
            "z-index": 10,
            "background-color": "#fafafa"
          },
          attrs: {
            app: !_vm.$vuetify.breakpoint.xsOnly,
            right: "",
            permanent: _vm.showNotificationPane,
            "hide-overlay": "",
            width: "375px",
            stateless: "",
            absolute: _vm.$vuetify.breakpoint.xsOnly
          },
          model: {
            value: _vm.showNotificationPane,
            callback: function($$v) {
              _vm.showNotificationPane = $$v
            },
            expression: "showNotificationPane"
          }
        },
        [
          _vm.showNotificationPane
            ? _c("NotificationsPane", {
                on: {
                  "close-notification-pane": function($event) {
                    _vm.showNotificationPane = false
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          staticClass: "elevation-3",
          staticStyle: { "z-index": "100" },
          attrs: {
            app: "",
            "hide-overlay": "",
            width: "375px",
            permanent: _vm.showGisInfoPanel && _vm.$vuetify.breakpoint.smAndUp,
            right: _vm.$vuetify.breakpoint.smAndUp,
            bottom: "",
            stateless: _vm.$vuetify.breakpoint.smAndUp
          },
          model: {
            value: _vm.showGisInfoPanel,
            callback: function($$v) {
              _vm.showGisInfoPanel = $$v
            },
            expression: "showGisInfoPanel"
          }
        },
        [
          _vm.showGisInfoPanel
            ? _c(
                "div",
                {
                  staticClass: "d-flex flex-column background",
                  staticStyle: { height: "100%" },
                  attrs: { id: "infoPanel" }
                },
                [
                  _c("GisInfoTopCard", {
                    attrs: {
                      selectedMapServiceId: _vm.selectedTask.mapServiceId,
                      globalId: _vm.selectedTask.globalId,
                      sharedSite: _vm.sharedSite
                    },
                    on: {
                      prev: _vm.prev,
                      next: _vm.next,
                      "gis-info-panel-close": _vm.onGisInfoPanelClose,
                      "open-expand-panel-dialog": function($event) {
                        _vm.showExpansionPanelDialog = true
                      },
                      "edited-site": _vm.createSharedSiteIfNotExist
                    }
                  }),
                  !["S"].includes(_vm.selectedLayerType)
                    ? _c("GisInfoButtonBar", {
                        attrs: {
                          selectedMapServiceId: _vm.selectedTask.mapServiceId,
                          globalId: _vm.selectedGlobalId,
                          selectedGisInfoIndex: _vm.selectedGisInfoIndex,
                          taskCount: _vm.taskCount
                        },
                        on: {
                          "gis-info-button-clicked": function($event) {
                            _vm.gisInfoTab = $event
                          }
                        }
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "background" },
                    [
                      _vm.showGisInfoPanel
                        ? _c("TicketFormDefDropdown", {
                            attrs: {
                              inRightPane: "",
                              selectedMapServiceId:
                                _vm.selectedTask.mapServiceId,
                              objectId: _vm.selectedTask.featureId,
                              globalId: _vm.selectedGlobalId,
                              selectedGisInfoObj: _vm.selectedGisInfoObj
                            },
                            on: {
                              "ticket-edit-form-submitted": function($event) {
                                _vm.onTicketEditFormSubmitted()
                                _vm.showUtiliBotDialogOnFormSubmitted()
                              },
                              "show-edit-dialog": function($event) {
                                _vm.openGisInfoPanels = [1]
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _vm.showGisInfoPanel
                    ? _c("SharePublicSite", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.selectedLayer.site_enabled,
                            expression: "selectedLayer.site_enabled"
                          }
                        ],
                        attrs: {
                          selectedMapServiceId: _vm.selectedMapServiceId,
                          objectId: _vm.selectedTask.featureId,
                          gisInfoAttributes: _vm.rawGisInfoAttributes,
                          sharedSite: _vm.sharedSite,
                          globalId: _vm.selectedTask.globalId
                        },
                        on: {
                          "public-link-created": _vm.createSharedSiteIfNotExist,
                          "removed-sharing": _vm.createSharedSiteIfNotExist,
                          "site-name-saved": _vm.createSharedSiteIfNotExist
                        }
                      })
                    : _vm._e(),
                  _vm.selectedLayer.site_enabled ? _c("v-divider") : _vm._e(),
                  ["F", "U"].includes(_vm.selectedLayerType)
                    ? _c(
                        "div",
                        { staticClass: "flex-grow-1 overflow-y-auto" },
                        [
                          _vm.showGisInfoPanel
                            ? _c("SortedGisInfo", {
                                attrs: {
                                  inRightPane: "",
                                  selectedMapServiceId:
                                    _vm.selectedTask.mapServiceId,
                                  globalId: _vm.globalId,
                                  objectId: _vm.objectId
                                },
                                on: {
                                  "esri-field-saved": function($event) {
                                    _vm.getGisInfo()
                                    _vm.reloadGisInfoPanel()
                                  },
                                  "utilisync-field-saved": function($event) {
                                    _vm.getGisInfo()
                                    _vm.reloadGisInfoPanel()
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c(
        "v-navigation-drawer",
        {
          staticStyle: { "z-index": "100" },
          attrs: {
            app: "",
            right: "",
            permanent: _vm.showFeatureLayerGisInfoPanel,
            "hide-overlay": "",
            width: "375px",
            stateless: ""
          },
          model: {
            value: _vm.showFeatureLayerGisInfoPanel,
            callback: function($$v) {
              _vm.showFeatureLayerGisInfoPanel = $$v
            },
            expression: "showFeatureLayerGisInfoPanel"
          }
        },
        [
          _vm.showFeatureLayerGisInfoPanel
            ? _c(
                "div",
                {
                  staticClass: "d-flex flex-column background",
                  staticStyle: { height: "100%" },
                  attrs: { id: "infoPanel" }
                },
                [
                  _c("FeatureItemGisInfoTopCard", {
                    attrs: {
                      selectedMapServiceId: _vm.selectedTask.mapServiceId,
                      objectId: _vm.selectedTask.featureId,
                      globalId: _vm.gisInfoId,
                      attributes: _vm.featureItemAttributes,
                      sharedSite: _vm.sharedSite
                    },
                    on: {
                      "gis-info-panel-close": function($event) {
                        _vm.showFeatureLayerGisInfoPanel = false
                      },
                      "open-expand-panel-dialog": function($event) {
                        _vm.showExpansionPanelDialog = true
                      },
                      "edited-site": _vm.createSharedSiteIfNotExist
                    }
                  }),
                  _c("FeatureItemButtonBar", {
                    attrs: {
                      globalId: _vm.selectedTask.globalId,
                      objectId: _vm.selectedTask.featureId,
                      selectedMapServiceId: _vm.selectedTask.mapServiceId,
                      taskCount: _vm.taskCount
                    },
                    on: {
                      "gis-info-button-clicked": function($event) {
                        _vm.gisInfoTab = $event
                      }
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "background" },
                    [
                      _vm.showFeatureLayerGisInfoPanel
                        ? _c("TicketFormDefDropdown", {
                            attrs: {
                              inRightPane: "",
                              objectId: _vm.selectedTask.featureId,
                              globalId: _vm.selectedTask.globalId,
                              selectedMapServiceId:
                                _vm.selectedTask.mapServiceId,
                              selectedGisInfoObj: _vm.selectedGisInfoObj
                            },
                            on: {
                              "ticket-edit-form-submitted": function($event) {
                                _vm.onTicketEditFormSubmitted()
                                _vm.showUtiliBotDialogOnFormSubmitted()
                              },
                              "show-edit-dialog": function($event) {
                                _vm.openGisInfoPanels = [1]
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _vm.showFeatureLayerGisInfoPanel
                    ? _c("SharePublicSite", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.selectedLayer.site_enabled,
                            expression: "selectedLayer.site_enabled"
                          }
                        ],
                        attrs: {
                          selectedMapServiceId: _vm.selectedTask.mapServiceId,
                          objectId: _vm.selectedTask.featureId,
                          attributes: _vm.featureItemAttributes,
                          sharedSite: _vm.sharedSite,
                          globalId: _vm.selectedTask.globalId
                        },
                        on: {
                          "public-link-created": _vm.createSharedSiteIfNotExist,
                          "removed-sharing": _vm.createSharedSiteIfNotExist,
                          "site-name-saved": _vm.createSharedSiteIfNotExist
                        }
                      })
                    : _vm._e(),
                  _vm.selectedLayer.site_enabled ? _c("v-divider") : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "flex-grow-1 overflow-y-auto" },
                    [
                      _c("SortedGisInfo", {
                        attrs: {
                          globalId: _vm.globalId,
                          objectId: _vm.objectId,
                          selectedMapServiceId: _vm.selectedTask.mapServiceId
                        },
                        on: {
                          "esri-field-saved": function($event) {
                            _vm.getGisInfo()
                            _vm.reloadGisInfoPanel()
                          },
                          "utilisync-field-saved": function($event) {
                            _vm.getGisInfo()
                            _vm.reloadGisInfoPanel()
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c(
        "v-navigation-drawer",
        {
          staticStyle: { "z-index": "101" },
          attrs: {
            app: "",
            right: "",
            permanent: _vm.showTasksTab,
            "hide-overlay": "",
            width: "375px",
            stateless: ""
          },
          model: {
            value: _vm.showTasksTab,
            callback: function($$v) {
              _vm.showTasksTab = $$v
            },
            expression: "showTasksTab"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "rounded-0 d-flex flex-column",
              staticStyle: { height: "100vh" }
            },
            [
              _c(
                "v-list",
                {
                  staticClass: "my-0 py-1",
                  attrs: { color: "#3F51B5", width: "100%" }
                },
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "py-0 my-0", attrs: { "two-line": "" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-space-between align-center"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "white--text",
                                  staticStyle: { "font-size": "20px" }
                                },
                                [_vm._v("Tasks")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticStyle: { "font-size": "12px" },
                                  attrs: { icon: "", text: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.showTasksTab = false
                                      _vm.gisInfoTab = undefined
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { color: "white" } }, [
                                    _vm._v(" " + _vm._s(_vm.mdiClose) + " ")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.showTasksTab
                ? [
                    ["U"].includes(_vm.selectedLayerType)
                      ? _c("TasksTab", {
                          staticClass: "overflow-y-auto",
                          attrs: {
                            globalId: _vm.gisInfoId,
                            objectId: _vm.selectedTask.featureId,
                            selectedMapServiceId: _vm.selectedTask.mapServiceId
                          },
                          on: {
                            "update-task-count": function($event) {
                              _vm.taskCount = $event
                            },
                            "tasks-refreshed": _vm.getAllTasks
                          }
                        })
                      : ["F"].includes(_vm.selectedLayerType)
                      ? _c("TasksTab", {
                          staticClass: "overflow-y-auto",
                          attrs: {
                            globalId: _vm.gisInfoId,
                            objectId: _vm.selectedTask.featureId,
                            selectedMapServiceId: _vm.selectedTask.mapServiceId
                          },
                          on: {
                            "update-task-count": function($event) {
                              _vm.taskCount = $event
                            },
                            "tasks-refreshed": _vm.getAllTasks
                          }
                        })
                      : _vm._e()
                  ]
                : _vm._e()
            ],
            2
          )
        ]
      ),
      _c(
        "v-navigation-drawer",
        {
          staticStyle: { "z-index": "101" },
          attrs: {
            app: "",
            right: "",
            permanent: _vm.showTicketLogTab,
            "hide-overlay": "",
            width: "375px",
            stateless: ""
          },
          model: {
            value: _vm.showTicketLogTab,
            callback: function($$v) {
              _vm.showTicketLogTab = $$v
            },
            expression: "showTicketLogTab"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "rounded-0 d-flex flex-column",
              staticStyle: { height: "100vh" }
            },
            [
              _c(
                "v-list",
                {
                  staticClass: "my-0 py-1",
                  attrs: { color: "#3F51B5", width: "100%" }
                },
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "py-0 my-0", attrs: { "two-line": "" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-space-between align-center"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "white--text",
                                  staticStyle: { "font-size": "20px" }
                                },
                                [_vm._v("Log")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticStyle: { "font-size": "12px" },
                                  attrs: { icon: "", text: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.showTicketLogTab = false
                                      _vm.gisInfoTab = undefined
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { color: "white" } }, [
                                    _vm._v(" " + _vm._s(_vm.mdiClose) + " ")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.showTicketLogTab
                ? [
                    ["U"].includes(_vm.selectedLayerType)
                      ? _c("Log", {
                          staticClass: "overflow-y-auto",
                          attrs: {
                            globalId: _vm.selectedTask.globalId,
                            objectId: _vm.selectedTask.featureId,
                            selectedMapServiceId: _vm.selectedTask.mapServiceId
                          }
                        })
                      : ["F"].includes(_vm.selectedLayerType)
                      ? _c("Log", {
                          staticClass: "overflow-y-auto",
                          attrs: {
                            globalId: _vm.selectedTask.globalId,
                            objectId: _vm.selectedTask.featureId,
                            selectedMapServiceId: _vm.selectedTask.mapServiceId
                          }
                        })
                      : _vm._e()
                  ]
                : _vm._e()
            ],
            2
          )
        ]
      ),
      _c(
        "v-navigation-drawer",
        {
          staticStyle: { "z-index": "101" },
          attrs: {
            app: "",
            right: "",
            permanent: _vm.showDocsTab,
            "hide-overlay": "",
            width: "375px",
            stateless: ""
          },
          model: {
            value: _vm.showDocsTab,
            callback: function($$v) {
              _vm.showDocsTab = $$v
            },
            expression: "showDocsTab"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "rounded-0 d-flex flex-column",
              staticStyle: { height: "100vh" }
            },
            [
              _c(
                "v-list",
                {
                  staticClass: "my-0 py-1",
                  attrs: { color: "#3F51B5", width: "100%" }
                },
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "py-0 my-0", attrs: { "two-line": "" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-space-between align-center"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "white--text",
                                  staticStyle: { "font-size": "20px" }
                                },
                                [_vm._v("Documents")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticStyle: { "font-size": "12px" },
                                  attrs: { icon: "", text: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.showDocsTab = false
                                      _vm.gisInfoTab = undefined
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { color: "white" } }, [
                                    _vm._v(" " + _vm._s(_vm.mdiClose) + " ")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.showDocsTab
                ? [
                    ["U"].includes(_vm.selectedLayerType)
                      ? _c("DocsTab", {
                          staticClass: "overflow-y-auto",
                          attrs: {
                            globalId: _vm.globalId,
                            objectId: _vm.objectId,
                            selectedMapServiceId: _vm.selectedTask.mapServiceId
                          }
                        })
                      : ["F"].includes(_vm.selectedLayerType)
                      ? _c("DocsTab", {
                          staticClass: "overflow-y-auto",
                          attrs: {
                            globalId: _vm.globalId,
                            objectId: _vm.objectId,
                            selectedMapServiceId: _vm.selectedTask.mapServiceId
                          }
                        })
                      : _vm._e()
                  ]
                : _vm._e()
            ],
            2
          )
        ]
      ),
      _c(
        "div",
        {
          ref: "filterDisplay",
          staticClass: "d-flex flex-wrap justify-space-between align-center"
        },
        [
          _c(
            "div",
            { staticClass: "d-flex px-5 align-center" },
            [
              _vm.onMapPage
                ? _c("TableViewDropdown", {
                    staticClass: "pt-4",
                    attrs: { currentSelectedTable: this.newSelectedTable },
                    on: {
                      "selected-table": function($event) {
                        return _vm.$emit("selected-table", $event)
                      }
                    }
                  })
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass: "px-1",
                  attrs: { text: "", color: "#3F51B5" },
                  on: {
                    click: function($event) {
                      _vm.showTasksFilterDialog = true
                    }
                  }
                },
                [
                  _c("v-icon", [_vm._v(" " + _vm._s(_vm.mdiFilter))]),
                  _vm._v(" Filter ")
                ],
                1
              ),
              _c("TasksPageFilterChoicesDisplay", {
                staticClass: "px-2",
                attrs: {
                  filterChoices: _vm.filterChoices,
                  users: _vm.users,
                  formDefinitions: _vm.formDefinitions,
                  taskTypes: _vm.taskTypes,
                  mapServices: _vm.mapServices
                },
                on: { "remove-filter": _vm.removeFilter }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "px-2 d-flex align-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "d-flex ma-0 pa-0 px-2",
                  attrs: { text: "", color: "#3F51B5" },
                  on: {
                    click: function($event) {
                      return _vm.reloadTasks()
                    }
                  }
                },
                [
                  _c("v-icon", { staticClass: "mr-1" }, [
                    _vm._v(" " + _vm._s(_vm.mdiRefresh) + " ")
                  ]),
                  _vm._v(" Reload ")
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      !_vm.loading
        ? _c("v-data-table", {
            staticClass: "pa-0 ma-0",
            attrs: {
              headers: _vm.filteredHeaders,
              items: _vm.filteredTasks,
              "item-key": "taskId",
              "hide-default-footer": "",
              "footer-props": {
                "items-per-page-options": [100]
              },
              "items-per-page": 100,
              "fixed-header": "",
              height: _vm.tableHeight
            },
            on: { "update:page": _vm.getFilteredTasks },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.menu",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function($event) {
                                  return _vm.startForm(item)
                                }
                              }
                            },
                            [
                              _c("v-icon", [
                                _vm._v(_vm._s(_vm.mdiNotePlusOutline))
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function($event) {
                                  return _vm.openInfoPanel(item)
                                }
                              }
                            },
                            [
                              _c("v-icon", [
                                _vm._v(_vm._s(_vm.mdiBookOpenOutline))
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-menu",
                            {
                              attrs: { "offset-y": "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              { attrs: { icon: "" } },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { dark: "" } },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.mdiDotsVertical
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _c(
                                "v-list",
                                { staticClass: "px-0 mx-0" },
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      staticClass: "mx-0 gap",
                                      on: {
                                        click: function($event) {
                                          return _vm.startForm(item)
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(_vm._s(_vm.mdiNotePlusOutline))
                                      ]),
                                      _vm._v(" Start Form ")
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    {
                                      staticClass: "mx-0 gap",
                                      on: {
                                        click: function($event) {
                                          return _vm.openInfoPanel(item)
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(_vm._s(_vm.mdiBookOpenOutline))
                                      ]),
                                      _vm._v(" Open Info Panel ")
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    {
                                      staticClass: "mx-0 gap",
                                      attrs: { disabled: !_vm.isOnline },
                                      on: {
                                        click: function($event) {
                                          return _vm.editTask(item)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { disabled: !_vm.isOnline } },
                                        [_vm._v(_vm._s(_vm.mdiPencil))]
                                      ),
                                      _vm._v(" Edit Scheduled Form ")
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    {
                                      staticClass: "mx-0 gap",
                                      attrs: { disabled: !_vm.isOnline },
                                      on: {
                                        click: function($event) {
                                          _vm.showConfirmDeleteDialog = true
                                          _vm.selectedTask = item
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { disabled: !_vm.isOnline } },
                                        [_vm._v(_vm._s(_vm.mdiDelete))]
                                      ),
                                      _vm._v(" Delete Task ")
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "item.due",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        " " + _vm._s(_vm._f("formatDayDate")(item.due)) + " "
                      )
                    ]
                  }
                },
                {
                  key: "item.assignedOn",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("formatDayDate")(item.assignedOn)) +
                          " "
                      )
                    ]
                  }
                },
                {
                  key: "item.assignedTo",
                  fn: function(ref) {
                    var item = ref.item
                    return [_vm._v(" " + _vm._s(_vm.getUserName(item)) + " ")]
                  }
                },
                {
                  key: "item.featureId",
                  fn: function(ref) {
                    var item = ref.item
                    return [_vm._v(" " + _vm._s(_vm.getSiteName(item)) + " ")]
                  }
                },
                {
                  key: "item.formDefinitionId",
                  fn: function(ref) {
                    var item = ref.item
                    return [_vm._v(" " + _vm._s(_vm.getFormName(item)) + " ")]
                  }
                },
                {
                  key: "item.assignedBy",
                  fn: function(ref) {
                    var item = ref.item
                    return [_vm._v(" " + _vm._s(_vm.getUserName(item)) + " ")]
                  }
                },
                {
                  key: "item.taskTypeId",
                  fn: function(ref) {
                    var item = ref.item
                    return [_vm._v(" " + _vm._s(_vm.getTaskType(item)) + " ")]
                  }
                },
                {
                  key: "footer",
                  fn: function(ref) {
                    var props = ref.props
                    var on = ref.on
                    return [
                      _c(
                        "div",
                        { ref: "bottomBar", staticClass: "d-flex justify-end" },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c(
                                "v-data-footer",
                                _vm._g(
                                  {
                                    staticStyle: { border: "none" },
                                    attrs: {
                                      "items-per-page-options": [100],
                                      options: props.options,
                                      pagination: props.pagination
                                    }
                                  },
                                  on
                                )
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "header.menu",
                  fn: function() {
                    return [
                      _c(
                        "v-menu",
                        {
                          attrs: { "offset-y": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "px-1",
                                            attrs: {
                                              icon: "",
                                              color: "#3F51B5"
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mr-1",
                                            attrs: { dark: "" }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.mdiPlusCircle) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            4049224740
                          )
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-card-text",
                                _vm._l(_vm.nonEmptyHeaders, function(h) {
                                  return _c("v-checkbox", {
                                    key: h.value,
                                    staticClass: "py-0 my-0",
                                    attrs: { label: h.text },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                      }
                                    },
                                    model: {
                                      value: _vm.headersEnabled[h.value],
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.headersEnabled,
                                          h.value,
                                          $$v
                                        )
                                      },
                                      expression: "headersEnabled[h.value]"
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              true
            )
          })
        : _c(
            "section",
            { staticClass: "d-flex justify-center align-center" },
            [
              _c("v-progress-circular", {
                attrs: { color: "#3F51B5", indeterminate: "" }
              })
            ],
            1
          ),
      _vm.showTaskPageEditTaskDialog
        ? _c("TaskPageEditTaskDialog", {
            attrs: {
              showTaskPageEditTaskDialog: _vm.showTaskPageEditTaskDialog,
              selectedTask: _vm.selectedTask,
              taskTypes: _vm.taskTypes
            },
            on: {
              "task-page-edit-task-dialog-close":
                _vm.onTaskPageEditTaskDialogClose,
              "edit-task-success": _vm.onEditTaskSuccess
            }
          })
        : _vm._e(),
      _vm.showTasksFilterDialog
        ? _c("TasksFilterDialog", {
            attrs: {
              showTasksFilterDialog: _vm.showTasksFilterDialog,
              tasks: _vm.tasks,
              users: _vm.users,
              formDefinitions: _vm.formDefinitions,
              taskTypes: _vm.taskTypes,
              siteInfos: _vm.siteInfos,
              mapServices: _vm.mapServices,
              savedFilterChoices: _vm.filterChoices
            },
            on: {
              "tasks-filter-dialog-close": function($event) {
                _vm.showTasksFilterDialog = false
              },
              "update-filter": function($event) {
                _vm.showTasksFilterDialog = false
                _vm.filterChoices = $event
              }
            }
          })
        : _vm._e(),
      _vm.showUnableToObtainGisAttributesDialog
        ? _c("UnableToObtainGisAttributes", {
            attrs: {
              showUnableToObtainGisAttributesDialog:
                _vm.showUnableToObtainGisAttributesDialog
            },
            on: {
              cancel: function($event) {
                _vm.showUnableToObtainGisAttributesDialog = false
                _vm.selectedTask = {}
                _vm.selectedFormResult = {}
              },
              "create-form": _vm.startFormWithoutGisInfo
            }
          })
        : _vm._e(),
      _c("ConfirmDeleteTaskDialog", {
        attrs: { showConfirmDeleteDialog: _vm.showConfirmDeleteDialog },
        on: {
          "cancel-delete-task": function($event) {
            _vm.showConfirmDeleteDialog = false
            _vm.selectedTask = {}
          },
          "delete-task": function($event) {
            _vm.showConfirmDeleteDialog = false
            _vm.deleteTask(_vm.selectedTask)
          }
        }
      }),
      _vm.showExpansionPanelDialog
        ? [
            ["U"].includes(_vm.selectedLayerType)
              ? _c("ExpandInfoPanelDialog", {
                  attrs: {
                    showExpansionPanelDialog: _vm.showExpansionPanelDialog,
                    selectedMapServiceId: _vm.selectedTask.mapServiceId,
                    globalId: _vm.globalId,
                    objectId: _vm.selectedTask.featureId,
                    selectedGisInfoObj: _vm.selectedGisInfoObj
                  },
                  on: {
                    "expand-info-panel-close": function($event) {
                      _vm.showExpansionPanelDialog = false
                    }
                  }
                })
              : ["F"].includes(_vm.selectedLayerType)
              ? _c("ExpandInfoPanelDialog", {
                  attrs: {
                    showExpansionPanelDialog: _vm.showExpansionPanelDialog,
                    selectedMapServiceId: _vm.selectedTask.mapServiceId,
                    globalId: _vm.globalId,
                    objectId: _vm.selectedTask.featureId,
                    selectedGisInfoObj: _vm.selectedGisInfoObj
                  },
                  on: {
                    "expand-info-panel-close": function($event) {
                      _vm.showExpansionPanelDialog = false
                    }
                  }
                })
              : _vm._e()
          ]
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "600px",
            persistent: "",
            fullscreen: _vm.isFullScreen
          },
          model: {
            value: _vm.showEditFormDialog,
            callback: function($$v) {
              _vm.showEditFormDialog = $$v
            },
            expression: "showEditFormDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _vm.showEditFormDialog
                ? _c("DynamicForm", {
                    attrs: {
                      formDefinition: _vm.selectedFormResult,
                      selectedMapServiceId: _vm.selectedTask.mapServiceId,
                      globalId: _vm.selectedTask.globalId,
                      objectId: _vm.selectedTask.featureId,
                      taskId: _vm.selectedTask.taskId,
                      canEdit: ""
                    },
                    on: {
                      "ticket-edit-form-close-button-click": function($event) {
                        _vm.showEditFormDialog = false
                      },
                      "ticket-edit-form-close": function($event) {
                        _vm.showEditFormDialog = false
                      },
                      "ticket-edit-form-submitted": function($event) {
                        _vm.onTicketEditFormSubmitted()
                        _vm.showUtiliBotDialogOnFormSubmitted()
                      },
                      input: function($event) {
                        _vm.selectedFormResult = $event
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm.showTasksOfflineDialog
        ? _c("OfflineDialog", {
            attrs: {
              showOfflineDialog: _vm.showTasksOfflineDialog,
              message:
                "This device is currently offline and a new form submission cannot be created on this GIS layer while offline. Please connect to the internet and try again."
            },
            on: {
              cancel: function($event) {
                _vm.showTasksOfflineDialog = false
              }
            }
          })
        : _vm._e(),
      _vm.showInfoPanelOfflineDialog
        ? _c("OfflineDialog", {
            attrs: {
              showOfflineDialog: _vm.showInfoPanelOfflineDialog,
              message:
                "Info panel is only available for UtiliSync layers while offline"
            },
            on: {
              cancel: function($event) {
                _vm.showInfoPanelOfflineDialog = false
              }
            }
          })
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function(ref) {
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      { attrs: { text: "" }, on: { click: _vm.viewForm } },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_vm._v(" View Form ")]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.showFormSubmittedSnackbar,
            callback: function($$v) {
              _vm.showFormSubmittedSnackbar = $$v
            },
            expression: "showFormSubmittedSnackbar"
          }
        },
        [
          _c("v-icon", { attrs: { dark: "" } }, [
            _vm._v(" " + _vm._s(_vm.mdiInformation) + " ")
          ]),
          _vm._v(" Success! The form was submitted. ")
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "600px",
            persistent: "",
            fullscreen: _vm.isFullScreen
          },
          model: {
            value: _vm.showViewFormEditFormDialog,
            callback: function($$v) {
              _vm.showViewFormEditFormDialog = $$v
            },
            expression: "showViewFormEditFormDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _vm.showViewFormEditFormDialog
                ? _c("DynamicForm", {
                    attrs: {
                      formDefinition:
                        _vm.formResultBeingViewed.selectedFormResult,
                      existingFormResultIdMap:
                        _vm.formResultBeingViewed.existingFormResultIdMap,
                      selectedPdfFileUrl:
                        _vm.formResultBeingViewed.selectedPdfFileUrl,
                      canEdit: false,
                      alreadySubmittedFinalOnline: "",
                      globalId: _vm.formResultBeingViewed.globalId,
                      objectId: _vm.formResultBeingViewed.objectId,
                      selectedMapServiceId:
                        _vm.formResultBeingViewed.selectedMapServiceId
                    },
                    on: {
                      "ticket-edit-form-close-button-click": function($event) {
                        _vm.showViewFormEditFormDialog = false
                        _vm.resetFormResultBeingViewed()
                      },
                      "ticket-edit-form-close": function($event) {
                        _vm.showViewFormEditFormDialog = false
                        _vm.resetFormResultBeingViewed()
                      },
                      "ticket-edit-form-submitted": function($event) {
                        _vm.showViewFormEditFormDialog = false
                        _vm.resetFormResultBeingViewed()
                        _vm.showUtiliBotDialogOnFormSubmitted()
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm.showAfterFormSubmissionUtilibotDialog
        ? _c("UtilibotDialog", {
            attrs: {
              showUtilibotDialog: _vm.showAfterFormSubmissionUtilibotDialog,
              formResultId: _vm.formSubmitted.formResultId,
              openedAfterFormSubmission: ""
            },
            on: {
              "utilibot-dialog-close": function($event) {
                _vm.showAfterFormSubmissionUtilibotDialog = false
              }
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }