<template>
  <v-dialog :value="showOfflineDialog" max-width="550px" persistent>
    <v-card>
      <v-card-text class="py-3">
        <h2 class="font-weight-regular">Not Available Offline</h2>
        <p class="py-3">
          {{ message }}
        </p>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn text color="#3F51B5" @click="$emit('cancel')">
          <span class="font-weight-regular">Cancel</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "OfflineDialog",
  props: {
    showOfflineDialog: Boolean,
    message: String,
  },
};
</script>
