<template>
  <v-dialog :value="showUnableToObtainGisAttributesDialog" max-width="550px">
    <v-card>
      <v-card-text class="py-3">
        <h2 class="font-weight-regular">Unable to Obtain GIS Attributes</h2>
        <p class="py-3">
          We were unable to obtain the GIS attributes for this feature. Do you
          want to continue with creating the form? If you continue, there may be
          some fields on the form that do not pre-populate as expected.
        </p>
      </v-card-text>

      <v-card-actions class="d-flex justify-end px-3">
        <v-btn text @click="$emit('cancel')">Cancel</v-btn>
        <v-btn @click="$emit('create-form')" dark color="#3F51B5">
          Create Form
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "UnableToObtainGisAttributes",
  props: {
    showUnableToObtainGisAttributesDialog: Boolean,
  },
};
</script>
