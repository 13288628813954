<template>
  <div class="d-flex flex-wrap gap">
    <v-chip
      v-for="(f, i) of filterChoices"
      :key="f.selectedField"
      close
      @click:close="$emit('remove-filter', i)"
    >
      <div class="chip my-0 py-0">
        {{ f.selectedField | taskFields }}
        <MatchIcon :value="f.selectedMatchChoice" class="mx-2" />
        {{ getDisplayMatchValue(f.selectedField, f.selectedMatchValue) }}
      </div>
    </v-chip>
  </div>
</template>

<script>
import MatchIcon from "@/components/list/gis-data-point-filter-dialog/MatchIcon";

export default {
  name: "TasksPageFilterChoicesDisplay",
  props: {
    filterChoices: Array,
    users: Array,
    formDefinitions: Array,
    taskTypes: Array,
    mapServices: Array,
  },
  components: {
    MatchIcon,
  },
  methods: {
    getDisplayMatchValue(selectedField, selectedMatchValue) {
      if (selectedField === "assignedTo" || selectedField === "assignedBy") {
        const user = this.users?.find((u) => u.user_id === selectedMatchValue);
        const { f_name: fName, l_name: lName } = user ?? {};
        return `${fName} ${lName}`;
      } else if (selectedField === "formDefinitionId") {
        return this.formDefinitions?.find((f) => {
          return selectedMatchValue === f?.form_definition_id;
        })?.form?.formDescription?.title;
      } else if (selectedField === "taskTypeId") {
        return this.taskTypes.find(
          (tt) => tt.task_type_id === selectedMatchValue
        )?.name;
      }
      return selectedMatchValue;
    },
  },
};
</script>
