<template>
  <div>
    <v-navigation-drawer
      :app="!$vuetify.breakpoint.xsOnly"
      v-model="showNotificationPane"
      right
      :permanent="showNotificationPane"
      hide-overlay
      width="375px"
      stateless
      :absolute="$vuetify.breakpoint.xsOnly"
      :style="{
        'z-index': 10,
        'background-color': '#fafafa',
      }"
    >
      <NotificationsPane
        v-if="showNotificationPane"
        @close-notification-pane="showNotificationPane = false"
      />
    </v-navigation-drawer>

    <v-navigation-drawer
      app
      v-model="showGisInfoPanel"
      style="z-index: 100"
      hide-overlay
      width="375px"
      :permanent="showGisInfoPanel && $vuetify.breakpoint.smAndUp"
      :right="$vuetify.breakpoint.smAndUp"
      bottom
      :stateless="$vuetify.breakpoint.smAndUp"
      class="elevation-3"
    >
      <div
        class="d-flex flex-column background"
        style="height: 100%"
        v-if="showGisInfoPanel"
        id="infoPanel"
      >
        <GisInfoTopCard
          :selectedMapServiceId="selectedTask.mapServiceId"
          :globalId="selectedTask.globalId"
          :sharedSite="sharedSite"
          @prev="prev"
          @next="next"
          @gis-info-panel-close="onGisInfoPanelClose"
          @open-expand-panel-dialog="showExpansionPanelDialog = true"
          @edited-site="createSharedSiteIfNotExist"
        />

        <GisInfoButtonBar
          @gis-info-button-clicked="gisInfoTab = $event"
          v-if="!['S'].includes(selectedLayerType)"
          :selectedMapServiceId="selectedTask.mapServiceId"
          :globalId="selectedGlobalId"
          :selectedGisInfoIndex="selectedGisInfoIndex"
          :taskCount="taskCount"
        />

        <div class="background">
          <TicketFormDefDropdown
            inRightPane
            v-if="showGisInfoPanel"
            :selectedMapServiceId="selectedTask.mapServiceId"
            :objectId="selectedTask.featureId"
            :globalId="selectedGlobalId"
            :selectedGisInfoObj="selectedGisInfoObj"
            @ticket-edit-form-submitted="
              onTicketEditFormSubmitted();
              showUtiliBotDialogOnFormSubmitted();
            "
            @show-edit-dialog="openGisInfoPanels = [1]"
          />
        </div>

        <v-divider></v-divider>

        <SharePublicSite
          v-if="showGisInfoPanel"
          v-show="selectedLayer.site_enabled"
          :selectedMapServiceId="selectedMapServiceId"
          :objectId="selectedTask.featureId"
          :gisInfoAttributes="rawGisInfoAttributes"
          :sharedSite="sharedSite"
          :globalId="selectedTask.globalId"
          @public-link-created="createSharedSiteIfNotExist"
          @removed-sharing="createSharedSiteIfNotExist"
          @site-name-saved="createSharedSiteIfNotExist"
        />

        <v-divider v-if="selectedLayer.site_enabled"></v-divider>

        <div
          v-if="['F', 'U'].includes(selectedLayerType)"
          class="flex-grow-1 overflow-y-auto"
        >
          <SortedGisInfo
            v-if="showGisInfoPanel"
            inRightPane
            :selectedMapServiceId="selectedTask.mapServiceId"
            :globalId="globalId"
            :objectId="objectId"
            @esri-field-saved="
              getGisInfo();
              reloadGisInfoPanel();
            "
            @utilisync-field-saved="
              getGisInfo();
              reloadGisInfoPanel();
            "
          />
        </div>
      </div>
    </v-navigation-drawer>

    <v-navigation-drawer
      app
      v-model="showFeatureLayerGisInfoPanel"
      right
      :permanent="showFeatureLayerGisInfoPanel"
      style="z-index: 100"
      hide-overlay
      width="375px"
      stateless
    >
      <div
        class="d-flex flex-column background"
        style="height: 100%"
        v-if="showFeatureLayerGisInfoPanel"
        id="infoPanel"
      >
        <FeatureItemGisInfoTopCard
          :selectedMapServiceId="selectedTask.mapServiceId"
          :objectId="selectedTask.featureId"
          :globalId="gisInfoId"
          :attributes="featureItemAttributes"
          :sharedSite="sharedSite"
          @gis-info-panel-close="showFeatureLayerGisInfoPanel = false"
          @open-expand-panel-dialog="showExpansionPanelDialog = true"
          @edited-site="createSharedSiteIfNotExist"
        />

        <FeatureItemButtonBar
          @gis-info-button-clicked="gisInfoTab = $event"
          :globalId="selectedTask.globalId"
          :objectId="selectedTask.featureId"
          :selectedMapServiceId="selectedTask.mapServiceId"
          :taskCount="taskCount"
        />

        <div class="background">
          <TicketFormDefDropdown
            inRightPane
            v-if="showFeatureLayerGisInfoPanel"
            :objectId="selectedTask.featureId"
            :globalId="selectedTask.globalId"
            :selectedMapServiceId="selectedTask.mapServiceId"
            :selectedGisInfoObj="selectedGisInfoObj"
            @ticket-edit-form-submitted="
              onTicketEditFormSubmitted();
              showUtiliBotDialogOnFormSubmitted();
            "
            @show-edit-dialog="openGisInfoPanels = [1]"
          />
        </div>

        <v-divider></v-divider>

        <SharePublicSite
          v-if="showFeatureLayerGisInfoPanel"
          v-show="selectedLayer.site_enabled"
          :selectedMapServiceId="selectedTask.mapServiceId"
          :objectId="selectedTask.featureId"
          :attributes="featureItemAttributes"
          :sharedSite="sharedSite"
          :globalId="selectedTask.globalId"
          @public-link-created="createSharedSiteIfNotExist"
          @removed-sharing="createSharedSiteIfNotExist"
          @site-name-saved="createSharedSiteIfNotExist"
        />

        <v-divider v-if="selectedLayer.site_enabled"></v-divider>

        <div class="flex-grow-1 overflow-y-auto">
          <SortedGisInfo
            :globalId="globalId"
            :objectId="objectId"
            :selectedMapServiceId="selectedTask.mapServiceId"
            @esri-field-saved="
              getGisInfo();
              reloadGisInfoPanel();
            "
            @utilisync-field-saved="
              getGisInfo();
              reloadGisInfoPanel();
            "
          />
        </div>
      </div>
    </v-navigation-drawer>

    <v-navigation-drawer
      app
      v-model="showTasksTab"
      right
      :permanent="showTasksTab"
      style="z-index: 101"
      hide-overlay
      width="375px"
      stateless
    >
      <div class="rounded-0 d-flex flex-column" style="height: 100vh">
        <v-list color="#3F51B5" class="my-0 py-1" width="100%">
          <v-list-item>
            <v-list-item-content class="py-0 my-0" two-line>
              <div class="d-flex justify-space-between align-center">
                <div class="white--text" style="font-size: 20px">Tasks</div>
                <v-btn
                  icon
                  text
                  @click="
                    showTasksTab = false;
                    gisInfoTab = undefined;
                  "
                  style="font-size: 12px"
                >
                  <v-icon color="white">
                    {{ mdiClose }}
                  </v-icon>
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <template v-if="showTasksTab">
          <TasksTab
            class="overflow-y-auto"
            v-if="['U'].includes(selectedLayerType)"
            :globalId="gisInfoId"
            :objectId="selectedTask.featureId"
            :selectedMapServiceId="selectedTask.mapServiceId"
            @update-task-count="taskCount = $event"
            @tasks-refreshed="getAllTasks"
          />
          <TasksTab
            class="overflow-y-auto"
            v-else-if="['F'].includes(selectedLayerType)"
            :globalId="gisInfoId"
            :objectId="selectedTask.featureId"
            :selectedMapServiceId="selectedTask.mapServiceId"
            @update-task-count="taskCount = $event"
            @tasks-refreshed="getAllTasks"
          />
        </template>
      </div>
    </v-navigation-drawer>

    <v-navigation-drawer
      app
      v-model="showTicketLogTab"
      right
      :permanent="showTicketLogTab"
      style="z-index: 101"
      hide-overlay
      width="375px"
      stateless
    >
      <div class="rounded-0 d-flex flex-column" style="height: 100vh">
        <v-list color="#3F51B5" class="my-0 py-1" width="100%">
          <v-list-item>
            <v-list-item-content class="py-0 my-0" two-line>
              <div class="d-flex justify-space-between align-center">
                <div class="white--text" style="font-size: 20px">Log</div>
                <v-btn
                  icon
                  text
                  @click="
                    showTicketLogTab = false;
                    gisInfoTab = undefined;
                  "
                  style="font-size: 12px"
                >
                  <v-icon color="white">
                    {{ mdiClose }}
                  </v-icon>
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <template v-if="showTicketLogTab">
          <Log
            class="overflow-y-auto"
            v-if="['U'].includes(selectedLayerType)"
            :globalId="selectedTask.globalId"
            :objectId="selectedTask.featureId"
            :selectedMapServiceId="selectedTask.mapServiceId"
          />
          <Log
            class="overflow-y-auto"
            v-else-if="['F'].includes(selectedLayerType)"
            :globalId="selectedTask.globalId"
            :objectId="selectedTask.featureId"
            :selectedMapServiceId="selectedTask.mapServiceId"
          />
        </template>
      </div>
    </v-navigation-drawer>

    <v-navigation-drawer
      app
      v-model="showDocsTab"
      right
      :permanent="showDocsTab"
      style="z-index: 101"
      hide-overlay
      width="375px"
      stateless
    >
      <div class="rounded-0 d-flex flex-column" style="height: 100vh">
        <v-list color="#3F51B5" class="my-0 py-1" width="100%">
          <v-list-item>
            <v-list-item-content class="py-0 my-0" two-line>
              <div class="d-flex justify-space-between align-center">
                <div class="white--text" style="font-size: 20px">Documents</div>
                <v-btn
                  icon
                  text
                  @click="
                    showDocsTab = false;
                    gisInfoTab = undefined;
                  "
                  style="font-size: 12px"
                >
                  <v-icon color="white">
                    {{ mdiClose }}
                  </v-icon>
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <template v-if="showDocsTab">
          <DocsTab
            class="overflow-y-auto"
            v-if="['U'].includes(selectedLayerType)"
            :globalId="globalId"
            :objectId="objectId"
            :selectedMapServiceId="selectedTask.mapServiceId"
          />
          <DocsTab
            class="overflow-y-auto"
            v-else-if="['F'].includes(selectedLayerType)"
            :globalId="globalId"
            :objectId="objectId"
            :selectedMapServiceId="selectedTask.mapServiceId"
          />
        </template>
      </div>
    </v-navigation-drawer>

    <div
      class="d-flex flex-wrap justify-space-between align-center"
      ref="filterDisplay"
    >
      <div class="d-flex px-5 align-center">
        <TableViewDropdown
          class="pt-4"
          v-if="onMapPage"
          @selected-table="$emit('selected-table', $event)"
          :currentSelectedTable="this.newSelectedTable"
        />
        <v-btn
          text
          color="#3F51B5"
          class="px-1"
          @click="showTasksFilterDialog = true"
        >
          <v-icon> {{ mdiFilter }}</v-icon>
          Filter
        </v-btn>

        <TasksPageFilterChoicesDisplay
          :filterChoices="filterChoices"
          :users="users"
          :formDefinitions="formDefinitions"
          :taskTypes="taskTypes"
          :mapServices="mapServices"
          @remove-filter="removeFilter"
          class="px-2"
        />
      </div>

      <div class="px-2 d-flex align-center">
        <v-btn
          text
          color="#3F51B5"
          class="d-flex ma-0 pa-0 px-2"
          @click="reloadTasks()"
        >
          <v-icon class="mr-1">
            {{ mdiRefresh }}
          </v-icon>
          Reload
        </v-btn>
      </div>
    </div>

    <v-data-table
      :headers="filteredHeaders"
      :items="filteredTasks"
      item-key="taskId"
      class="pa-0 ma-0"
      hide-default-footer
      :footer-props="{
        'items-per-page-options': [100],
      }"
      :items-per-page="100"
      @update:page="getFilteredTasks"
      fixed-header
      :height="tableHeight"
      v-if="!loading"
    >
      <template v-slot:[`item.menu`]="{ item }">
        <div class="d-flex">
          <v-btn icon @click="startForm(item)">
            <v-icon>{{ mdiNotePlusOutline }}</v-icon>
          </v-btn>
          <v-btn icon @click="openInfoPanel(item)">
            <v-icon>{{ mdiBookOpenOutline }}</v-icon>
          </v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon dark>
                  {{ mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </template>

            <v-list class="px-0 mx-0">
              <v-list-item class="mx-0 gap" @click="startForm(item)">
                <v-icon>{{ mdiNotePlusOutline }}</v-icon>
                Start Form
              </v-list-item>
              <v-list-item class="mx-0 gap" @click="openInfoPanel(item)">
                <v-icon>{{ mdiBookOpenOutline }}</v-icon>
                Open Info Panel
              </v-list-item>
              <v-list-item
                class="mx-0 gap"
                @click="editTask(item)"
                :disabled="!isOnline"
              >
                <v-icon :disabled="!isOnline">{{ mdiPencil }}</v-icon> Edit
                Scheduled Form
              </v-list-item>
              <v-list-item
                class="mx-0 gap"
                :disabled="!isOnline"
                @click="
                  showConfirmDeleteDialog = true;
                  selectedTask = item;
                "
              >
                <v-icon :disabled="!isOnline">{{ mdiDelete }}</v-icon> Delete
                Task
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>

      <template v-slot:[`item.due`]="{ item }">
        {{ item.due | formatDayDate }}
      </template>

      <template v-slot:[`item.assignedOn`]="{ item }">
        {{ item.assignedOn | formatDayDate }}
      </template>

      <template v-slot:[`item.assignedTo`]="{ item }">
        {{ getUserName(item) }}
      </template>

      <template v-slot:[`item.featureId`]="{ item }">
        {{ getSiteName(item) }}
      </template>

      <template v-slot:[`item.formDefinitionId`]="{ item }">
        {{ getFormName(item) }}
      </template>

      <template v-slot:[`item.assignedBy`]="{ item }">
        {{ getUserName(item) }}
      </template>

      <template v-slot:[`item.taskTypeId`]="{ item }">
        {{ getTaskType(item) }}
      </template>

      <template v-slot:footer="{ props, on }">
        <div class="d-flex justify-end" ref="bottomBar">
          <div class="d-flex">
            <v-data-footer
              :items-per-page-options="[100]"
              :options="props.options"
              :pagination="props.pagination"
              v-on="on"
              style="border: none"
            >
            </v-data-footer>
          </div>
        </div>
      </template>

      <template v-slot:[`header.menu`]>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon color="#3F51B5" class="px-1">
              <v-icon dark class="mr-1">
                {{ mdiPlusCircle }}
              </v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-text>
              <v-checkbox
                v-for="h of nonEmptyHeaders"
                :key="h.value"
                @click.stop
                v-model="headersEnabled[h.value]"
                :label="h.text"
                class="py-0 my-0"
              >
              </v-checkbox>
            </v-card-text>
          </v-card>
        </v-menu>
      </template>
    </v-data-table>
    <section v-else class="d-flex justify-center align-center">
      <v-progress-circular color="#3F51B5" indeterminate></v-progress-circular>
    </section>

    <TaskPageEditTaskDialog
      v-if="showTaskPageEditTaskDialog"
      :showTaskPageEditTaskDialog="showTaskPageEditTaskDialog"
      :selectedTask="selectedTask"
      :taskTypes="taskTypes"
      @task-page-edit-task-dialog-close="onTaskPageEditTaskDialogClose"
      @edit-task-success="onEditTaskSuccess"
    />

    <TasksFilterDialog
      v-if="showTasksFilterDialog"
      :showTasksFilterDialog="showTasksFilterDialog"
      :tasks="tasks"
      :users="users"
      :formDefinitions="formDefinitions"
      :taskTypes="taskTypes"
      :siteInfos="siteInfos"
      :mapServices="mapServices"
      :savedFilterChoices="filterChoices"
      @tasks-filter-dialog-close="showTasksFilterDialog = false"
      @update-filter="
        showTasksFilterDialog = false;
        filterChoices = $event;
      "
    />

    <UnableToObtainGisAttributes
      v-if="showUnableToObtainGisAttributesDialog"
      :showUnableToObtainGisAttributesDialog="
        showUnableToObtainGisAttributesDialog
      "
      @cancel="
        showUnableToObtainGisAttributesDialog = false;
        selectedTask = {};
        selectedFormResult = {};
      "
      @create-form="startFormWithoutGisInfo"
    />

    <ConfirmDeleteTaskDialog
      :showConfirmDeleteDialog="showConfirmDeleteDialog"
      @cancel-delete-task="
        showConfirmDeleteDialog = false;
        selectedTask = {};
      "
      @delete-task="
        showConfirmDeleteDialog = false;
        deleteTask(selectedTask);
      "
    />

    <template v-if="showExpansionPanelDialog">
      <ExpandInfoPanelDialog
        v-if="['U'].includes(selectedLayerType)"
        :showExpansionPanelDialog="showExpansionPanelDialog"
        :selectedMapServiceId="selectedTask.mapServiceId"
        :globalId="globalId"
        :objectId="selectedTask.featureId"
        :selectedGisInfoObj="selectedGisInfoObj"
        @expand-info-panel-close="showExpansionPanelDialog = false"
      />
      <ExpandInfoPanelDialog
        v-else-if="['F'].includes(selectedLayerType)"
        :showExpansionPanelDialog="showExpansionPanelDialog"
        :selectedMapServiceId="selectedTask.mapServiceId"
        :globalId="globalId"
        :objectId="selectedTask.featureId"
        :selectedGisInfoObj="selectedGisInfoObj"
        @expand-info-panel-close="showExpansionPanelDialog = false"
      />
    </template>

    <v-dialog
      v-model="showEditFormDialog"
      max-width="600px"
      persistent
      :fullscreen="isFullScreen"
    >
      <v-card>
        <DynamicForm
          :formDefinition="selectedFormResult"
          :selectedMapServiceId="selectedTask.mapServiceId"
          :globalId="selectedTask.globalId"
          :objectId="selectedTask.featureId"
          :taskId="selectedTask.taskId"
          canEdit
          @ticket-edit-form-close-button-click="showEditFormDialog = false"
          @ticket-edit-form-close="showEditFormDialog = false"
          @ticket-edit-form-submitted="
            onTicketEditFormSubmitted();
            showUtiliBotDialogOnFormSubmitted();
          "
          @input="selectedFormResult = $event"
          v-if="showEditFormDialog"
        />
      </v-card>
    </v-dialog>

    <OfflineDialog
      :showOfflineDialog="showTasksOfflineDialog"
      v-if="showTasksOfflineDialog"
      message="This device is currently offline and a new form submission cannot be created on this GIS layer while offline. Please connect to the internet and try again."
      @cancel="showTasksOfflineDialog = false"
    />

    <OfflineDialog
      :showOfflineDialog="showInfoPanelOfflineDialog"
      v-if="showInfoPanelOfflineDialog"
      message="Info panel is only available for UtiliSync layers while offline"
      @cancel="showInfoPanelOfflineDialog = false"
    />

    <v-snackbar v-model="showFormSubmittedSnackbar">
      <v-icon dark>
        {{ mdiInformation }}
      </v-icon>
      Success! The form was submitted.

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="viewForm"> View Form </v-btn>
      </template>
    </v-snackbar>

    <v-dialog
      v-model="showViewFormEditFormDialog"
      max-width="600px"
      persistent
      :fullscreen="isFullScreen"
    >
      <v-card>
        <DynamicForm
          :formDefinition="formResultBeingViewed.selectedFormResult"
          :existingFormResultIdMap="
            formResultBeingViewed.existingFormResultIdMap
          "
          :selectedPdfFileUrl="formResultBeingViewed.selectedPdfFileUrl"
          :canEdit="false"
          alreadySubmittedFinalOnline
          :globalId="formResultBeingViewed.globalId"
          :objectId="formResultBeingViewed.objectId"
          :selectedMapServiceId="formResultBeingViewed.selectedMapServiceId"
          @ticket-edit-form-close-button-click="
            showViewFormEditFormDialog = false;
            resetFormResultBeingViewed();
          "
          @ticket-edit-form-close="
            showViewFormEditFormDialog = false;
            resetFormResultBeingViewed();
          "
          @ticket-edit-form-submitted="
            showViewFormEditFormDialog = false;
            resetFormResultBeingViewed();
            showUtiliBotDialogOnFormSubmitted();
          "
          v-if="showViewFormEditFormDialog"
        />
      </v-card>
    </v-dialog>

    <UtilibotDialog
      v-if="showAfterFormSubmissionUtilibotDialog"
      :showUtilibotDialog="showAfterFormSubmissionUtilibotDialog"
      :formResultId="formSubmitted.formResultId"
      openedAfterFormSubmission
      @utilibot-dialog-close="showAfterFormSubmissionUtilibotDialog = false"
    />
  </div>
</template>

<script>
import NotificationsPane from "@/components/shared/NotificationsPane.vue";
import TaskPageEditTaskDialog from "@/components/tasks/TaskPageEditTaskDialog.vue";
import TasksFilterDialog from "@/components/tasks/TasksFilterDialog.vue";
import {
  mdiFilter,
  mdiPlusCircle,
  mdiDotsVertical,
  mdiPencil,
  mdiDelete,
  mdiClose,
  mdiNotePlusOutline,
  mdiBookOpenOutline,
  mdiInformation,
  mdiRefresh,
} from "@mdi/js";
import { axiosWithRegularAuth, axiosWithNoAuth } from "@/plugins/axios";
import GisInfoTopCard from "@/components/mapView/GisInfoTopCard";
import GisInfoButtonBar from "@/components/mapView/GisInfoButtonBar.vue";
import FeatureItemGisInfoTopCard from "@/components/list/FeatureItemGisInfoTopCard";
import FeatureItemButtonBar from "@/components/list/FeatureItemButtonBar.vue";
import gisInfoMixin from "@/mixins/gisInfoMixin";
import moment from "moment";
import TicketFormDefDropdown from "@/components/tickets/TicketFormDefDropdown.vue";
import Log from "@/components/tickets/Log.vue";
import DocsTab from "@/components/mapView/DocsTab.vue";
import TasksTab from "@/components/mapView/TasksTab.vue";
import DynamicForm from "@/components/tickets/ticket-edit-form/DynamicForm";
import MATCH_CHOICE_VALUES from "@/constants/matchChoiceValues";
import TasksPageFilterChoicesDisplay from "@/components/tasks/TasksPageFilterChoicesDisplay";
import UnableToObtainGisAttributes from "@/components/tasks/UnableToObtainGisAttributes";
import ExpandInfoPanelDialog from "@/components/mapView/ExpandInfoPanelDialog.vue";
import ConfirmDeleteTaskDialog from "@/components/tasks/ConfirmDeleteTaskDialog";
import downloadDataMixin from "@/mixins/downloadDataMixin";
import { db } from "@/mixins/utilisync-db";
import bulkDownloadDataMixin from "@/mixins/bulkDownloadDataMixin";
import signOutMixin from "@/mixins/signOutMixin";
import TABS from "@/constants/tabs";
import getUtiliSyncLayerFeatureSiteName from "@/mixins/getUtiliSyncLayerFeatureSiteName";
import Dexie from "dexie";
import OfflineDialog from "@/components/tasks/OfflineDialog";
import networkStatusMixin from "@/mixins/networkStatusMixin";
import TableViewDropdown from "@/components/mapView/TableViewDropdown";
import { getGisInfoObjectId } from "@/mixins/getId";
import SharePublicSite from "@/components/app/SharePublicSite";
import sharedSiteMixin from "@/mixins/sharedSiteMixin";
import { mapGetters, mapMutations } from "vuex";
import fullScreenCheckMixin from "@/mixins/fullScreenCheckMixin";
import getActiveTaskCountMixin from "@/mixins/getActiveTaskCountMixin";
import SortedGisInfo from "@/components/mapView/SortedGisInfo";
import showUtiliBotDialogOnFormSubmittedMixin from "@/mixins/showUtiliBotDialogOnFormSubmittedMixin";
import UtilibotDialog from "@/components/tickets/ticket-edit-form/dynamic-form/dynamic-form-edit-actions/UtilibotDialog";

const APIURL = process.env.VUE_APP_API_URL;
const modulo = (n, m) => {
  return ((n % m) + m) % m;
};
const headers = [
  {
    text: "Due",
    align: "start",
    value: "due",
    sortable: true,
    minWidth: "120px",
    width: "120px",
  },
  {
    text: "Assigned To",
    align: "start",
    value: "assignedTo",
    sortable: true,
    minWidth: "120px",
    width: "120px",
  },
  {
    text: "Form",
    align: "start",
    value: "formDefinitionId",
    sortable: true,
    minWidth: "120px",
    width: "120px",
  },
  {
    text: "Description",
    align: "start",
    value: "description",
    sortable: true,
    minWidth: "120px",
    width: "120px",
  },
  {
    text: "Site",
    align: "start",
    value: "featureId",
    sortable: true,
    minWidth: "120px",
    width: "120px",
  },
  {
    text: "Site Address",
    align: "start",
    value: "siteAddress",
    sortable: true,
    minWidth: "120px",
    width: "120px",
  },
  {
    text: "Task Type",
    align: "start",
    value: "taskTypeId",
    sortable: true,
    minWidth: "120px",
    width: "120px",
  },
  {
    text: "Status",
    align: "start",
    value: "status",
    sortable: true,
    minWidth: "120px",
    width: "120px",
  },
  {
    text: "Assigned By",
    align: "start",
    value: "assignedBy",
    sortable: true,
    minWidth: "120px",
    width: "120px",
  },
  {
    text: "Assigned On",
    align: "start",
    value: "assignedOn",
    sortable: true,
    minWidth: "120px",
    width: "120px",
  },

  {
    text: "",
    align: "start",
    value: "menu",
    sortable: false,
    minWidth: "120px",
    width: "120px",
  },
];
const getFilterValue = (filterChoices, field) => {
  return filterChoices?.find((fc) => {
    const { selectedField } = fc;
    return selectedField === field;
  })?.selectedMatchValue;
};

export default {
  name: "MapViewTasksView",
  components: {
    NotificationsPane,
    TaskPageEditTaskDialog,
    GisInfoTopCard,
    GisInfoButtonBar,
    FeatureItemGisInfoTopCard,
    FeatureItemButtonBar,
    TicketFormDefDropdown,
    Log,
    DocsTab,
    TasksTab,
    DynamicForm,
    TasksFilterDialog,
    TasksPageFilterChoicesDisplay,
    UnableToObtainGisAttributes,
    ExpandInfoPanelDialog,
    ConfirmDeleteTaskDialog,
    OfflineDialog,
    TableViewDropdown,
    SharePublicSite,
    SortedGisInfo,
    UtilibotDialog,
  },
  mixins: [
    networkStatusMixin,
    gisInfoMixin,
    downloadDataMixin,
    bulkDownloadDataMixin,
    signOutMixin,
    sharedSiteMixin,
    fullScreenCheckMixin,
    getActiveTaskCountMixin,
    showUtiliBotDialogOnFormSubmittedMixin,
  ],
  props: {
    newSelectedTable: String,
    onMapPage: Boolean,
    bottomPaneHeight: Number,
    progressBarHeight: Number,
    inMapView: Boolean,
    topBarHeight: Number,
  },
  data() {
    return {
      showTasksOfflineDialog: false,
      showInfoPanelOfflineDialog: false,
      showTicketLogTab: false,
      showTasksTab: false,
      showDocsTab: false,
      showNotificationPane: false,
      mdiFilter,
      mdiPlusCircle,
      mdiDotsVertical,
      mdiPencil,
      mdiDelete,
      mdiClose,
      mdiNotePlusOutline,
      mdiBookOpenOutline,
      mdiInformation,
      mdiRefresh,
      tasks: [],
      headers,
      gisInfos: [],
      headersEnabled: {
        due: true,
        assignedTo: true,
        formDefinitionId: true,
        description: true,
        featureId: true,
        siteAddress: true,
        status: false,
        assignedBy: false,
        assignedOn: false,
        taskTypeId: true,
        menu: true,
      },
      formDefinitions: [],
      mapServices: [],
      users: [],
      taskTypes: [],
      showTaskPageEditTaskDialog: false,
      selectedTask: {},
      showGisInfoPanel: false,
      gisInfoTab: undefined,
      showFeatureLayerGisInfoPanel: false,
      showExpansionPanelDialog: false,
      selectedGisInfoIndex: 0,
      selectedGisInfo: {},
      featureItemFields: [],
      featureItemAttributes: {},
      selectedFormResult: {},
      showEditFormDialog: false,
      showTasksFilterDialog: false,
      filterChoices: [
        {
          selectedField: "status",
          selectedMatchChoice: MATCH_CHOICE_VALUES.EQUALS,
          selectedMatchValue: "Open",
        },
      ],
      showUnableToObtainGisAttributesDialog: false,
      siteInfos: [],
      showConfirmDeleteDialog: false,
      tableHeight: 0,
      taskCount: undefined,
      layers: [],
      selectedMapServiceId: undefined,
      showFormSubmittedSnackbar: false,
      showViewFormEditFormDialog: false,
      formResultBeingViewed: {
        selectedFormResult: {},
        existingFormResultIdMap: {},
        selectedPdfFileUrl: "",
        globalId: undefined,
        objectId: undefined,
        selectedMapServiceId: undefined,
      },
      loading: false,
      globalId: undefined,
      gisInfoId: undefined,
      taskSharedSites: [],
    };
  },
  computed: {
    ...mapGetters(["formSubmitted", "showingNotificationPane"]),
    selectedLayerFields() {
      return this.selectedLayer?.fields;
    },
    selectedLayer() {
      const selectedLayer = this.mapServices?.find((l) => {
        return this.selectedTask.mapServiceId === l.map_service_id;
      });
      return { ...selectedLayer };
    },
    featureId() {
      return getGisInfoObjectId(this.selectedGisInfoObj);
    },
    rawGisInfoAttributes() {
      if (this.notUtiliSyncLayer) {
        if (
          !this.featureItemAttributes ||
          !Array.isArray(this.featureItemFields)
        ) {
          return {};
        }

        const entries = this.featureItemFields.map(({ name: key }) => {
          const value = this.featureItemAttributes[key];
          return [key, value];
        });
        return Object.fromEntries(entries);
      } else {
        return {};
      }
    },
    gisInfoAttributes() {
      if (this.notUtiliSyncLayer) {
        if (
          !this.featureItemAttributes ||
          !Array.isArray(this.featureItemFields)
        ) {
          return {};
        }

        const entries = this.featureItemFields.map(({ name: key }) => {
          const value = this.featureItemAttributes[key];

          return [this.findAliasByName(this.featureItemFields, key), value];
        });
        return Object.fromEntries(entries);
      } else {
        return {};
      }
    },
    notUtiliSyncLayer() {
      const mapService = this.mapServices.find(
        (m) => m?.map_service_id === this.selectedTask?.mapServiceId
      );
      return mapService?.service_type !== "U";
    },
    selectedGlobalId() {
      return getGisInfoObjectId(this.selectedGisInfoObj);
    },
    selectedGisInfoObj() {
      return this.gisInfos?.[this.selectedGisInfoIndex];
    },
    mappedTasks() {
      const { taskSharedSites } = this;
      return this.tasks.map((t) => {
        const sharedSite = taskSharedSites.find((s) => {
          const {
            map_service_id: taskMapServiceId,
            feature_id: taskFeatureId,
          } = t;
          const { map_service_id: mapServiceId, feature_id: featureId } = s;
          return (
            taskMapServiceId === mapServiceId && taskFeatureId === featureId
          );
        });
        const {
          due,
          assigned_to: assignedTo,
          global_id: globalId,
          form_definition_id: formDefinitionId,
          description,
          feature_id: featureId,
          map_service_id: mapServiceId,
          task_id: taskId,
          status,
          assigned_by: assignedBy,
          assigned_on: assignedOn,
          task_type_id: taskTypeId,
        } = t;
        return {
          due,
          assignedTo,
          globalId,
          featureId,
          formDefinitionId,
          description,
          mapServiceId,
          taskId,
          status,
          assignedBy,
          assignedOn,
          taskTypeId,
          siteAddress: sharedSite?.address,
        };
      });
    },
    filteredTasks() {
      const selectedStatus = this.filterChoices?.find((fc) => {
        const { selectedField } = fc;
        return selectedField === "status";
      })?.selectedMatchValue;
      if (["Open"].includes(selectedStatus)) {
        const tasks = this.mappedTasks.filter((t) => {
          const isMatch = this.filterChoices?.every((fc) => {
            const { selectedField, selectedMatchChoice, selectedMatchValue } =
              fc;
            if (selectedMatchChoice === MATCH_CHOICE_VALUES.CONTAINS) {
              return t?.[selectedField]?.includes(selectedMatchValue);
            } else if (
              selectedMatchChoice === MATCH_CHOICE_VALUES.DOES_NOT_CONTAIN
            ) {
              return !t?.[selectedField]?.includes(selectedMatchValue);
            } else if (selectedMatchChoice === MATCH_CHOICE_VALUES.EQUALS) {
              if (selectedField === "featureId") {
                return this.getSiteName(t) === selectedMatchValue;
              } else {
                return t?.[selectedField] === selectedMatchValue;
              }
            } else if (
              selectedMatchChoice === MATCH_CHOICE_VALUES.DOES_NOT_EQUAL
            ) {
              if (selectedField === "featureId") {
                return this.getSiteName(t) !== selectedMatchValue;
              } else {
                return t?.[selectedField] !== selectedMatchValue;
              }
            } else if (
              selectedMatchChoice === MATCH_CHOICE_VALUES.ON_OR_AFTER
            ) {
              return moment(t?.[selectedField]).isSameOrAfter(
                selectedMatchValue
              );
            } else if (
              selectedMatchChoice === MATCH_CHOICE_VALUES.ON_OR_BEFORE
            ) {
              return moment(t?.[selectedField]).isSameOrBefore(
                selectedMatchValue
              );
            } else if (selectedMatchChoice === MATCH_CHOICE_VALUES.RANGE) {
              const [start, end] = selectedMatchValue;
              return moment(t?.[selectedField]).isBetween(
                start,
                moment(end, "YYYY-MM-DD").add(24, "hours"),
                null,
                "[]"
              );
            }
            return false;
          });
          return isMatch;
        });
        return tasks.sort((a, b) => new Date(a.due) - new Date(b.due));
      } else {
        return this.mappedTasks;
      }
    },
    filteredHeaders() {
      return this.headers.filter((h) => {
        return this.headersEnabled[h.value] || h.value === "menu";
      });
    },
    nonEmptyHeaders() {
      return this.headers.filter((h) => {
        return Boolean(h.text);
      });
    },
    selectedLayerType() {
      return this.mapServices.find(
        (m) => m.map_service_id === this.selectedTask?.mapServiceId
      )?.service_type;
    },
  },
  methods: {
    ...mapMutations(["setFormSubmitted", "setShowingNotificationPane"]),
    async getSharedSitesByTaskMapServiceIds() {
      const mapServiceIds = this.tasks.map((t) => t.map_service_id);
      const {
        data: { results },
      } = await axiosWithRegularAuth.post(
        `${APIURL}/shared_sites_by_map_service_id`,
        {
          map_service_ids: mapServiceIds,
        }
      );
      this.taskSharedSites = results;
    },
    async reloadTasks() {
      await this.bulkDownloadTasks();
      await this.getAllTasks();
      await this.getSharedSitesByTaskMapServiceIds();
    },
    resetFormResultBeingViewed() {
      this.formResultBeingViewed = {
        selectedFormResult: {},
        existingFormResultIdMap: {},
        selectedPdfFileUrl: "",
        globalId: undefined,
        objectId: undefined,
        selectedMapServiceId: undefined,
      };
    },
    async viewForm() {
      const { formResultId } = this.formSubmitted;
      const { data: formResult } = await axiosWithRegularAuth.get(
        `${APIURL}/form_results/${formResultId}`
      );
      const { feature_id: objectId, map_service_id: mapServiceId } = formResult;
      const { gisDataPointId } = formResult.feature_attributes;
      this.formResultBeingViewed = {
        selectedFormResult: formResult,
        existingFormResultIdMap: {
          formResultId,
        },
        selectedPdfFileUrl: formResult.pdfFileUrl,
        globalId: gisDataPointId,
        objectId,
        selectedMapServiceId: mapServiceId,
      };
      this.showViewFormEditFormDialog = true;
      this.setFormSubmitted({
        isFormSubmitted: false,
        formResultId: undefined,
      });
    },
    onResize() {
      if (this.inMapView) {
        const height =
          this.bottomPaneHeight -
          this.progressBarHeight -
          (this.$refs.filterDisplay?.clientHeight ?? 80) -
          (this.$refs.bottomBar?.clientHeight ?? 40) -
          20;
        this.tableHeight = Math.max(height, 150);
      } else {
        const height =
          window.innerHeight -
          (this.$refs.filterDisplay?.clientHeight ?? 80) -
          (this.$refs.bottomBar?.clientHeight ?? 40) -
          (this.topBarHeight ?? 56) -
          20;
        this.tableHeight = Math.max(height, 200);
      }
    },
    async reloadGisInfoPanel() {
      this.loadGisData();
    },
    async onTaskPageEditTaskDialogClose() {
      this.showTaskPageEditTaskDialog = false;
      await this.getAllTasks();
      await this.getSharedSitesByTaskMapServiceIds();
    },
    startFormWithoutGisInfo() {
      this.showEditFormDialog = true;
      this.showUnableToObtainGisAttributesDialog = false;
    },
    async removeFilter(index) {
      this.filterChoices.splice(index, 1);
      await this.$nextTick();
      this.tasks = await db.tasks.toCollection().toArray();
    },
    findAliasByName(fields, name) {
      const field = fields?.find((f) => f.name === name);
      return field?.alias || name;
    },
    async onTicketEditFormSubmitted() {
      await this.$nextTick();
      this.showEditFormDialog = false;
      this.showViewFormEditFormDialog = false;
      this.showGisInfoPanel = false;
      this.showMobileGisInfoPanel = false;
      this.showFeatureLayerGisInfoPanel = false;
    },
    async startForm(task) {
      this.selectedTask = { ...task };
      this.selectedFormResult = this.formDefinitions.find((f) => {
        return f.form_definition_id === task?.formDefinitionId;
      });
      const [results] = await db.mapServices
        .filter((m) => m.map_service_id === this.selectedTask.mapServiceId)
        .toArray();
      if (results?.service_type === "U") {
        this.showEditFormDialog = true;
      } else {
        if (!this.isOnline) {
          this.showTasksOfflineDialog = true;
          return;
        }
        try {
          const mapServiceUrl = results.service_url;
          const { data } = await axiosWithNoAuth.get(`${mapServiceUrl}/query`, {
            params: {
              objectids: this.selectedTask?.featureId,
              outFields: "*",
              f: "json",
              token: localStorage.getItem("esri_token"),
            },
          });
          if (data.features?.length === 0) {
            this.showUnableToObtainGisAttributesDialog = true;
          } else {
            this.showEditFormDialog = true;
          }
        } catch (error) {
          this.showUnableToObtainGisAttributesDialog = true;
        }
      }
    },
    async loadGisData() {
      let queryResult = {};
      const [results] = await db.mapServices
        .filter((m) => m.map_service_id === this.selectedTask.mapServiceId)
        .toArray();
      try {
        const mapServiceUrl = results.service_url;
        const { data } = await axiosWithNoAuth.get(`${mapServiceUrl}/query`, {
          params: {
            objectids: this.selectedTask?.featureId,
            outFields: "*",
            f: "json",
            token: localStorage.getItem("esri_token"),
          },
        });
        queryResult = data;
      } catch (error) {
        console.log(error);
      } finally {
        const { features, fields } = queryResult;
        const [feature] = features ?? [];
        this.featureItemAttributes = feature?.attributes ?? {};
        this.featureItemFields = fields;
        const { globalIdFieldName } = queryResult;
        this.globalId = feature?.attributes?.[globalIdFieldName];
      }
    },
    async openInfoPanel(task) {
      this.gisInfos = [];
      this.selectedTask = { ...task };
      this.objectId = this.selectedTask.featureId;
      this.showGisInfoPanel = false;
      this.showFeatureLayerGisInfoPanel = false;
      const [selectedLayer] = await db.mapServices
        .filter((m) => m.map_service_id === task?.mapServiceId)
        .toArray();
      if (selectedLayer?.service_type === "U") {
        this.showGisInfoPanel = true;
        this.selectedMapServiceId = this.selectedTask.mapServiceId;
        this.objectId = this.selectedTask.featureId;
        const [point] = await db.gisDataPoints
          .filter((p) => {
            return (
              p.map_service_id === this.selectedMapServiceId &&
              p.object_id === this.objectId
            );
          })
          .toArray();
        this.gisInfoId = point?.gis_data_point_id;
        this.globalId = point?.gis_data_point_id;
        await this.createSharedSiteIfNotExist();
      } else if (selectedLayer?.service_type === "F") {
        if (!this.isOnline) {
          this.showInfoPanelOfflineDialog = true;
          return;
        }
        this.showFeatureLayerGisInfoPanel = true;
        this.selectedMapServiceId = this.selectedTask.mapServiceId;
        this.objectId = this.selectedTask.featureId;
        this.gisInfoId = this.selectedTask.globalId;
        await this.loadGisData();
        await this.createSharedSiteIfNotExist();
      }
    },
    editTask(task) {
      this.selectedTask = { ...task };
      this.showTaskPageEditTaskDialog = true;
    },
    getTaskType(task) {
      return this.taskTypes.find((tt) => tt.task_type_id === task?.taskTypeId)
        ?.name;
    },
    getUserName(task) {
      const user = this.users.find((u) => u.user_id === task?.assignedTo);
      const { f_name: fName, l_name: lName } = user ?? {};
      return `${fName} ${lName}`;
    },
    getFormName(task) {
      return this.formDefinitions.find((f) => {
        return task?.formDefinitionId === f?.form_definition_id;
      })?.form?.formDescription?.title;
    },
    getSiteName(task) {
      const { taskSharedSites } = this;
      const sharedSite = taskSharedSites.find((s) => {
        const { map_service_id: mapServiceId, feature_id: featureId } = s;
        return (
          task?.mapServiceId === mapServiceId && task?.featureId === featureId
        );
      });
      const site = this.siteInfos.find((s) => {
        return (
          s?.feature_id === task?.featureId &&
          s?.map_service_id === task?.mapServiceId
        );
      });
      return sharedSite?.name ?? site?.siteName;
    },

    async getFilteredTasks() {
      const selectedStatus = this.filterChoices?.find((fc) => {
        const { selectedField } = fc;
        return selectedField === "status";
      })?.selectedMatchValue;
      if (["Open"].includes(selectedStatus)) {
        this.tasks = await db.tasks.toCollection().toArray();
      } else {
        const {
          data: { results: closedTasks },
        } = await axiosWithRegularAuth.get(
          `${APIURL}/tasks/user_group_not_open`,
          {
            params: {
              status: selectedStatus,
            },
          }
        );
        this.tasks = closedTasks;
      }
    },
    async getOpenTasks() {
      const assignedTo = this.filterChoices.find(
        (fc) => fc.selectedField === "assignedTo"
      )?.selectedMatchValue;
      this.tasks = await db.tasks
        .filter((t) => {
          return t.assigned_to === assignedTo && t.status === "Open";
        })
        .toArray();
    },
    async getClosedTasks() {
      const assignedTo = this.filterChoices.find(
        (fc) => fc.selectedField === "assignedTo"
      )?.selectedMatchValue;
      const {
        data: { results: closedTasks },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/tasks/user_group_not_open`,
        {
          params: {
            assigned_to: assignedTo,
          },
        }
      );
      this.tasks = closedTasks;
    },
    async onEditTaskSuccess(taskId) {
      this.showTaskPageEditTaskDialog = false;
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(`${APIURL}/tasks/${taskId}`);
      await db.tasks.put(results);
      await this.getAllTasks();
      await this.getSharedSitesByTaskMapServiceIds();
    },
    async getAllTasks() {
      this.loading = true;
      const selectedStatus = this.filterChoices?.find((fc) => {
        const { selectedField } = fc;
        return selectedField === "status";
      })?.selectedMatchValue;

      const auth = JSON.parse(localStorage.getItem("auth"));
      const assignedTo = this.filterChoices.find(
        (fc) => fc.selectedField === "assignedTo"
      )?.selectedMatchValue;

      if (selectedStatus === "Open") {
        if (assignedTo === auth?.user_id) {
          await this.getOpenTasks();
        } else {
          await this.getFilteredTasks();
        }
      } else if (selectedStatus === "Closed") {
        await this.getClosedTasks();
      } else {
        await this.getFilteredTasks();
      }
      this.loading = false;
    },
    async getGisInfo() {
      const tasks = await db.tasks.toCollection().toArray();
      const taskMapServiceIds = [
        ...new Set(tasks.map((t) => t.map_service_id)),
      ];
      const featureMapServices = await db.mapServices
        .filter(
          (m) =>
            m.service_type === "F" &&
            taskMapServiceIds.includes(m.map_service_id)
        )
        .toArray();
      let featureLayerSiteNames = [];
      if (this.isOnline) {
        const queryPromises = featureMapServices.map(
          async ({ map_service_id: mapServiceId }) => {
            const {
              data: { results: selectedMapService },
            } = await axiosWithRegularAuth.get(
              `${APIURL}/map_services/${mapServiceId}`
            );
            const mapServiceUrl = selectedMapService?.service_url;
            if (!mapServiceUrl) {
              return [];
            }
            try {
              const { data: queryResult } = await axiosWithNoAuth.get(
                `${mapServiceUrl}/query`,
                {
                  params: {
                    where: "1=1",
                    outFields: "*",
                    f: "json",
                    token: localStorage.getItem("esri_token"),
                  },
                }
              );
              const { objectIdFieldName, features, error } = queryResult;
              if (error) {
                return [];
              }
              return features.map((f) => {
                return {
                  map_service_id: mapServiceId,
                  feature_id: f?.attributes?.[objectIdFieldName],
                  siteName: f?.attributes?.[selectedMapService?.ref_field],
                };
              });
            } catch (error) {
              return [];
            }
          }
        );
        featureLayerSiteNames = (await Promise.all(queryPromises)).flat();
      }

      const gisDataFields = await db.gisDataFields.toCollection().toArray();
      const gisDataValues = await db.gisDataValues.toCollection().toArray();
      const mapServices = await db.mapServices
        .filter((m) => m.service_type === "U")
        .toArray();
      const utiliSyncLayerSiteNamePromises = mapServices.map(async (m) => {
        const { map_service_id: mapServiceId } = m;
        const gisDataPoints = await db.gisDataPoints
          .filter((p) => p.map_service_id === mapServiceId)
          .toArray();
        const promises = gisDataPoints.map(async (p) => {
          const siteName = await getUtiliSyncLayerFeatureSiteName({
            gisDataFields,
            gisDataValues,
            mapServiceId,
            objectId: p.object_id,
          });
          return {
            feature_id: p.object_id,
            map_service_id: mapServiceId,
            siteName,
          };
        });
        return Promise.all(promises);
      });
      const utiliSyncLayerSiteNames = (
        await Promise.all(utiliSyncLayerSiteNamePromises)
      ).flat();

      this.siteInfos = [...featureLayerSiteNames, ...utiliSyncLayerSiteNames];
    },
    async getFormDefinitions() {
      this.formDefinitions = await db.formDefinitions.toCollection().toArray();
    },
    async getMapServices() {
      this.mapServices = await db.mapServices.toCollection().toArray();
    },
    async getUsers() {
      this.users = await db.users.toCollection().toArray();
    },
    async getTaskTypes() {
      this.taskTypes = await db.taskTypes.toCollection().toArray();
    },
    async deleteTask(task) {
      let existingTask;
      try {
        const {
          data: { results },
        } = await axiosWithRegularAuth.get(`${APIURL}/tasks/${task.taskId}`);
        existingTask = results;
      } catch (error) {
        console.log(error);
      }

      if (existingTask) {
        await axiosWithRegularAuth.delete(`${APIURL}/tasks/${task.taskId}`);
      }
      await db.tasks.filter((t) => t.task_id === task.taskId).delete();
      this.selectedTask = {};
      await this.getAllTasks();
      await this.getSharedSitesByTaskMapServiceIds();
    },
    async prev() {
      this.showMobileGisInfoPanel = false;
      this.showGisInfoPanel = false;
      this.selectedGisInfoIndex = modulo(
        this.selectedGisInfoIndex - 1,
        this.gisInfos.length
      );
      await this.$nextTick();
      if (this.$vuetify.breakpoint.xsOnly) {
        this.showMobileGisInfoPanel = true;
        this.selectedMapServiceId = this.selectedTask.mapServiceId;
        this.objectId = this.selectedTask.featureId;
        await this.createSharedSiteIfNotExist();
      } else {
        this.showGisInfoPanel = true;
        this.selectedMapServiceId = this.selectedTask.mapServiceId;
        this.objectId = this.selectedTask.featureId;
        await this.createSharedSiteIfNotExist();
      }
    },
    async next() {
      this.showMobileGisInfoPanel = false;
      this.showGisInfoPanel = false;
      this.selectedGisInfoIndex = modulo(
        this.selectedGisInfoIndex + 1,
        this.gisInfos.length
      );
      await this.$nextTick();
      if (this.$vuetify.breakpoint.xsOnly) {
        this.showMobileGisInfoPanel = true;
        this.selectedMapServiceId = this.selectedTask.mapServiceId;
        this.objectId = this.selectedTask.featureId;
        await this.createSharedSiteIfNotExist();
      } else {
        this.showGisInfoPanel = true;
        this.selectedMapServiceId = this.selectedTask.mapServiceId;
        this.objectId = this.selectedTask.featureId;
        await this.createSharedSiteIfNotExist();
      }
    },
    onGisInfoPanelClose() {
      this.showGisInfoPanel = false;
      this.gisInfos = [];
    },
    initializeFilterChoices() {
      const auth = JSON.parse(localStorage.getItem("auth"));
      this.filterChoices = [
        {
          selectedField: "status",
          selectedMatchChoice: MATCH_CHOICE_VALUES.EQUALS,
          selectedMatchValue: "Open",
        },
        {
          selectedField: "assignedTo",
          selectedMatchChoice: MATCH_CHOICE_VALUES.EQUALS,
          selectedMatchValue: auth?.user_id,
        },
      ];
    },
  },
  async beforeMount() {
    this.initializeFilterChoices();
    try {
      if (localStorage.getItem("bulk-download-complete") !== "true") {
        await this.bulkDownloadData();
      }
      await this.bulkDownloadUsers();
      await this.getAllTasks();
      await this.getUsers();
      await this.getGisInfo();
      await this.getFormDefinitions();
      await this.getMapServices();
      await this.getTaskTypes();
      await this.getSharedSitesByTaskMapServiceIds();
      localStorage.setItem("bulk-download-complete", true);
      const lastFullDownloadCompleted = localStorage.getItem(
        "last-full-download-completed"
      );
      const fullDownload =
        !lastFullDownloadCompleted ||
        (lastFullDownloadCompleted &&
          moment().diff(moment(lastFullDownloadCompleted), "hours") >= 12);
      await this.downloadData(!fullDownload);
    } catch (error) {
      if (Object.values(Dexie.errnames).includes(error?.name)) {
        this.signOut();
      }
    } finally {
      await this.getUsers();
      await this.getGisInfo();
      await this.getFormDefinitions();
      await this.getMapServices();
      await this.getTaskTypes();
      await this.getAllTasks();
      await this.getSharedSitesByTaskMapServiceIds();
    }
  },
  async mounted() {
    window.addEventListener("resize", this.onResize);
    await this.$nextTick();
    this.onResize();
  },
  beforeDestroy() {
    const [elHtml] = document.getElementsByTagName("html");
    elHtml.style.overflowY = "auto";
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
    showingNotificationPane: {
      immediate: true,
      async handler(val) {
        if (val) {
          await this.$nextTick();
          this.showTicketLogTab = false;
          this.showTasksTab = false;
          this.showDocsTab = false;
          this.showGisInfoPanel = false;
          this.showFeatureLayerGisInfoPanel = false;
          this.setShowingNotificationPane(false);
        }
      },
    },
    formSubmitted: {
      deep: true,
      async handler({ isFormSubmitted }) {
        if (isFormSubmitted) {
          this.showFormSubmittedSnackbar = true;
          await this.reloadTasks();
        }
      },
    },
    bottomPaneHeight: {
      immediate: true,
      handler() {
        this.onResize();
      },
    },
    progressBarHeight: {
      immediate: true,
      handler() {
        this.onResize();
      },
    },
    topBarHeight: {
      immediate: true,
      handler() {
        this.onResize();
      },
    },
    filterChoices: {
      deep: true,
      async handler(newVal, oldVal) {
        const oldStatus = getFilterValue(oldVal, "status");
        const newStatus = getFilterValue(newVal, "status");
        if (newStatus !== oldStatus) {
          await this.getAllTasks();
          await this.getSharedSitesByTaskMapServiceIds();
        }
        this.onResize();
      },
    },
    gisInfoTab(val) {
      this.showTicketLogTab = false;
      this.showTasksTab = false;
      this.showDocsTab = false;
      if (val === TABS.TASKS_TAB) {
        this.showTasksTab = true;
      } else if (val === TABS.TICKET_LOG_TAB) {
        this.showTicketLogTab = true;
      } else if (val === TABS.DOCS_TAB) {
        this.showDocsTab = true;
      }
    },
    selectedGisInfoIndex: {
      immediate: true,
      async handler(val) {
        const gisInfo = this.gisInfos?.[val];
        if (!gisInfo) {
          return;
        }
        await this.getGisInfoData(gisInfo);
      },
    },
    gisInfos: {
      deep: true,
      immediate: true,
      async handler(val) {
        const gisInfo = val?.[this.selectedGisInfoIndex];
        if (!gisInfo) {
          return;
        }
        await this.getGisInfoData(gisInfo);
      },
    },
  },
};
</script>
